import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import KonkretAnalogiaTulajdonsagai from '../analogiadb/hibajelentes/KonkretAnalogiaTulajdonsagai';
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"
import {useFetching} from "../auth/apicalls"
import HibajelentesTorles from "./hibajelentes/HibajelentesTorles"
import HibajelentesSzavazat from './hibajelentes/HibajelentesSzavazat';
import { usePermissions } from '../userkezeles/PermissionContext';

const HibajelentesTargyalo = () => {
  const [hibajelentesek, setHibajelentesek] = useState([]);
  const { data: hibajelentesekData, isError, isLoading } = useFetching('/api/analogiahibajelentes/');
  const { hibajelentesSzavazatJog } = usePermissions();

  useEffect(() => {
    const fetchPosts = async () => {
      // sortedHibajelentesek
      if (hibajelentesekData) {
        const sortedHibajelentesek = hibajelentesekData.sort((a, b) => {
          if (a.lezart_elfogadott === null && b.lezart_elfogadott !== null) {
            return -1;
          } else if (a.lezart_elfogadott !== null && b.lezart_elfogadott === null) {
            return 1;
          }
          return 0;
        });
        setHibajelentesek(sortedHibajelentesek);
      }
    };
    fetchPosts();
  }, [hibajelentesekData]);

  const getTitle = (hibajelentes) => {
    if (hibajelentes.tartalmi_hiba) {
      return 'Tartalmi hiba';
    }
    else if (hibajelentes.csoport_besorolasi_hiba) {
      return 'Csoport besorolási hiba';
    }
    else if (hibajelentes.aanalogia_besorolasi_hiba) {
      return 'Absztrakt analógia besorolási hiba';
    }
    else if (hibajelentes.torlendo_analogia) {
      return 'Törlendő analógia';
    }
  };

  return (
    <>
    {isLoading && <Loading size={60} />}
    {isError && <ErrorAlert show={isError} text="" />}
    {hibajelentesekData && (
    <div className="row my-4">
      <h1 className="text-center mb-1">Analógia hibajelentés tárgyaló</h1>
      <div className='text-muted text-center mb-1'>Vitassuk meg, hogy ez egyes hibásnak jelölt analógiák tényleg hibásak-e és javíthatóak-e a megadott módon! </div>
      {hibajelentesSzavazatJog >= 3 &&
        <>
          <div className='text-muted text-center mb-1'>Ha egy hibajelentés eléri a 20 pontot, elfogadásra kerül, ha a -10 pontot, akkor elutasításra kerül</div>
          <div className='text-muted text-center mb-5'>Tapasztalt felhasználó szavazata többet érhet (pont = jelenlegi szinted)</div>
        </>
      }

      {hibajelentesek.map((hibajelentes, index) => (
        <div
          className={`card mb-4 border-0 ${hibajelentes.lezart_elfogadott != null ? 'bg-secondary' : ''}`}
          key={index}
        >
          <div className='card-header row p-3'>
            <div className='col-6 text-center'><h3>{getTitle(hibajelentes)} </h3></div>
            <div className='col-6 text-center'>
            {hibajelentes.lezart_elfogadott === null && (
              <p>Hibajelentés validálása <b>folyamatban</b></p>
            )}
            {hibajelentes.lezart_elfogadott === true && (
              <p>Hibajelentés <b>elfogadva</b>, az analógia frissítésre került</p>
            )}
            {hibajelentes.lezart_elfogadott === false && (
              <p>Hibajelentés <b>elutasítva</b>, az analógia nem került frissítésre</p>
            )}
            </div>
          </div>
          
          <div className="card-body">
              <KonkretAnalogiaTulajdonsagai analogiaAdatok={hibajelentes.analogia} 
              hibasszempont={hibajelentes.aanalogia_besorolasi_hiba ? 'aanalogia_besorolasi_hiba' :
          (hibajelentes.tartalmi_hiba ?
            'tartalmi_hiba' :
            (hibajelentes.csoport_besorolasi_hiba ?
              'csoport_besorolasi_hiba' :
              (hibajelentes.torlendo_analogia ?
                'torlendo_analogia' :
                '')
                )
              )
            }/>
              <div className='row'>
                <div className="col-8">
                  <h5>Eredeti érték:</h5>
                  <div className="text-danger">
                  {hibajelentes.eredeti_ertek}
                  </div>
                  <h5 className='mt-2'>Javítási javaslat:</h5>
                  <div className="text-success">
                  {hibajelentes.aanalogia_besorolasi_hiba && (
                    <>{hibajelentes.aanalogia_besorolasi_hiba}</>
                  )}
                  {hibajelentes.tartalmi_hiba && (
                    <>{hibajelentes.tartalmi_hiba}</>
                  )}
                  {hibajelentes.csoport_besorolasi_hiba && (
                    <>{hibajelentes.csoport_besorolasi_hiba}</>
                  )}
                  {hibajelentes.torlendo_analogia && (
                    <>Az analógia több szempontból is helytelen, így törölni kell!</>
                  )}
                  </div>
                  
                </div>
                <div className="col-4">
                <HibajelentesSzavazat {...{hibajelentes}}/>
                <div class="btn-group w-100 mt-2">
                  <button type="button" class="btn btn-primary disabled">Pont</button>
                  <button type="button" class="btn btn-white disabled">{hibajelentes.ertekeles}</button>
                </div>
                <HibajelentesTorles {...{hibajelentes}}/>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h5>Leírás/magyarázat:</h5>
                  <p className="card-text"> { hibajelentes.leiras } </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>)}
    </>
  );
};

export default HibajelentesTargyalo;
