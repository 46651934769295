import React, { useState, useEffect } from 'react';
import { Container, Alert, Button } from 'react-bootstrap';
import { useSpring, animated } from '@react-spring/web';
import './Finish.css'; // Make sure to create this CSS file for custom styling
import { FaArrowLeft } from 'react-icons/fa';

function Finish({courseId, handleBack}) {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMore(true);
    }, 1000); // 3 seconds delay

    return () => clearTimeout(timer);
  }, []);

  const initialAnimationProps = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 180, friction: 12 }
  });

  const additionalAnimationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: showMore ? 1 : 0 },
    config: { duration: 1300 }
  });

  return (
    <Container className="finish-container">
      <animated.div style={initialAnimationProps}>
        <Alert variant="success" className="animated-alert p-5">
          <Alert.Heading><h1>Gratulálok!</h1></Alert.Heading>
          <p>Sikeresen elvégezted ezt a leckét!</p>
          <p>Böngéssz tovább a leckék között további hasonló tartalmakért!</p>
        </Alert>
      </animated.div>
      {showMore && (
        <div className='row'>
            <animated.div style={additionalAnimationProps} className="col">
            <Alert variant="info" className="animated-alert">
              <Alert.Heading>Vissza az előző feladathoz</Alert.Heading>
              <Button variant="secondary" onClick={handleBack}>
                <><FaArrowLeft /> <div className='mx-3'>Vissza</div></>
              </Button>
            </Alert>
          </animated.div>
          <animated.div style={additionalAnimationProps} className="col">
            <Alert variant="info" className="animated-alert">
              <Alert.Heading>Vissza a Lecke Központhoz</Alert.Heading>
              <Button variant="primary" href={`/tanulokozpont/${courseId}/lecke`}>Lecke Központ</Button>
            </Alert>
          </animated.div>
          <animated.div style={additionalAnimationProps} className="col">
            <Alert variant="info" className="animated-alert">
              <Alert.Heading>Vissza a Kurzus Központhoz</Alert.Heading>
              <Button variant="primary" href="/tanulokozpont">Kurzus Központ</Button>
            </Alert>
          </animated.div>
        </div>
      )}
    </Container>
  );
}

export default Finish;
