import React, { useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Modal } from 'react-bootstrap';
import { useFetching } from '../../auth/apicalls';
import { FaEdit, FaTrash, FaPlus, FaEye, FaLevelUpAlt, FaPlay } from 'react-icons/fa';
import { ErrorAlert } from '../../alert/ErrorAlert';
import { Loading } from '../../alert/Loading';
import DeleteConfirmation from './DeleteConfirmation';
import LevelFormAdd from './LevelFormAdd';
import './LessonList.css';
import AuthContext from "../../userkezeles/AuthContext";

function LessonList() {
  let { isSzerkeszto, user } = useContext(AuthContext);
  const { courseId } = useParams();
  const { data: lessons, isError, isLoading } = useFetching(`/api/courses/${courseId}/lessons/`, false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [showLevels, setShowLevels] = useState(false);
  const [levels, setLevels] = useState([]);
  const [showAddLevelModal, setShowAddLevelModal] = useState(false);

  const handleCloseAddLevelModal = () => {
    setShowAddLevelModal(false);
    setSelectedLessonId(null);
  };

  const canOpenLogic = (necessary_level) => {
    if (!user && necessary_level === 0) return true
    if (!user) return false
    if (user.felhasznaloi_szint >= necessary_level ) return true
    else return false
  };

  const handleAddLevelSuccess = () => {
    window.location.reload();
  };

  const handleDeleteClick = (id) => {
    setSelectedLessonId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLessonId(null);
  };

  const handleShowAddLevelModal = (lessonId) => {
    setSelectedLessonId(lessonId);
    setShowAddLevelModal(true);
  };

  const handleDeleteSuccess = () => {
    window.location.reload();
  };

  const handleShowLevels = async (lessonId) => {
    const response = await fetch(`/api/courses/${courseId}/lessons/${lessonId}/levels/`);
    const data = await response.json();
    setLevels(data);
    setShowLevels(true);
    setSelectedLessonId(lessonId);
  };

  const handleCloseLevels = () => setShowLevels(false);

  return (
    <>
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="" />}
      {lessons && (
        <>
        <Button variant="primary" href="/tanulokozpont">Vissza a tanulóközpontba</Button>
          <h1 className="lesson-list-title my-5">Leckék</h1>
          {isSzerkeszto && (
            <Button as={Link} to={`/tanulokozpont/${courseId}/lecke/new`} className="btn btn-success mb-3">
              <FaPlus /> Add Lesson
            </Button>
          )}
          <Row>
            {lessons.map((lesson) => (
              <>{(lesson.current_level  || isSzerkeszto ) &&
              <Col md={4} key={lesson.id} className="mb-4">
                <Card className="lesson-card">
                  <Card.Body>
                    <Card.Title>{lesson.title}</Card.Title>
                    <Card.Text>{lesson.description}</Card.Text>
                    {lesson.badge && <Card.Text><strong>Érme:</strong> {lesson.badge}</Card.Text>}
                    {lesson.current_level && <Card.Text><strong>Elérhető pontok:</strong> {lesson.current_level.points}</Card.Text>}
                    <Card.Text>
                    {lesson.current_level &&
                    <Button variant="success"as={Link}
                        to={`/tanulokozpont/${courseId}/lecke/${lesson.id}/melyseg/${lesson.current_level.id}/start`}
                        className={`ml-2 ${!canOpenLogic(lesson.current_level.necessary_level) ? 'disabled' : ''}`}>
                      <FaPlay className='mx-2'/>
                      {
                        lesson.levels.length > 1 ?
                        <>{lesson.current_level.level_number}. szint indítása</>
                        :
                        <>Indítás</>
                      }
                    </Button>}
                    {
                      (lesson.levels.length > 1  || isSzerkeszto ) &&
                      <Button variant="info" onClick={() => handleShowLevels(lesson.id)} className="mr-2">
                        <FaEye /> <div className='mx-2'>Mélységek</div>
                      </Button>
                    }
                    </Card.Text>
                    {isSzerkeszto && (
                      <div className="lesson-actions">
                        <Button as={Link} to={`/tanulokozpont/${courseId}/lecke/${lesson.id}`} className="btn btn-info mr-2">
                          <FaEdit /> Edit
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteClick(lesson.id)} className="mr-2">
                          <FaTrash /> Delete
                        </Button>
                        <Button variant="secondary" onClick={() => handleShowAddLevelModal(lesson.id)}>
                          <FaLevelUpAlt /> Add Level
                        </Button>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              }</>
            ))}
          </Row>
          <DeleteConfirmation
            show={showModal}
            handleClose={handleCloseModal}
            lessonId={selectedLessonId}
            onSuccess={handleDeleteSuccess}
          />
          <LevelFormAdd
            show={showAddLevelModal}
            handleClose={handleCloseAddLevelModal}
            onSuccess={handleAddLevelSuccess}
            lessonId={selectedLessonId}
          />
        </>
      )}

      <Modal show={showLevels} onHide={handleCloseLevels} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Lecke mélységek</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {levels.map((level) => (
              <Col md={6} key={level.id} className="mb-4">
                <Card className="level-card">
                  <Card.Body>
                    <Card.Title>Mélység {level.level_number}</Card.Title>
                    <Card.Text><strong>Szükséges felhasználói szint:</strong> {level.necessary_level}</Card.Text>
                    <Card.Text><strong>Elérhető pontok:</strong> {level.points}</Card.Text>
                    <Card.Text>{level.description}</Card.Text>
                    <div className="level-actions">
                      { isSzerkeszto &&
                        <Button as={Link} to={`/tanulokozpont/${courseId}/lecke/${selectedLessonId}/melyseg/${level.id}`} className="btn btn-info mr-2">
                        <FaEdit /> Edit
                      </Button>}
                      <Button
                          variant="success"
                          as={Link}
                          to={`/tanulokozpont/${courseId}/lecke/${selectedLessonId}/melyseg/${level.id}/start`}
                          className={`ml-2 ${!canOpenLogic(level.necessary_level) ? 'disabled' : ''}`}
                        >      <FaPlay /> Start
                        </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLevels}>
            Close
          </Button>
          { isSzerkeszto &&
          <Button variant="primary" onClick={() => handleShowAddLevelModal(selectedLessonId)}>
            Add Level
          </Button> }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LessonList;
