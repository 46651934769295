import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContext from "../../userkezeles/AuthContext";
import {usePost} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";

export const KommentFeltoltes = ({ activePostId, post, setActivePostId, news, setNews }) => {
  let {user} = useContext(AuthContext);
  const [commentText, setCommentText] = useState('');
  const queryClient = useQueryClient();

  const { mutate: postKomment} = usePost(`/api/hir/${activePostId}/add_comment/`, {
      onSuccess: () => {
        queryClient.invalidateQueries("/api/hir/");
      },
      onError: (error) => {
          showErrorAlert({
              title: "Nem sikerült létrehozni a kommentet!",
              text: error.response.data.user_error
            });
      },
    });

  const handleCommentSubmit = async () => {
    postKomment({szoveg: commentText})
  };

  return (
    <>
      {activePostId === post.id && (
            <div className="mt-2">
            <input
                type="text"
                className="form-control"
                placeholder="Szólj hozzá valamit..."
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
            />
            <button
                className="btn btn-primary mt-2"
                onClick={() => handleCommentSubmit()}
            >
                Kommentelés
            </button>
            </div>
        )}
    </>
  );
};
