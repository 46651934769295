import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDelete } from '../../auth/apicalls';

const CourseDelete = ({ show, handleClose, courseId, onSuccess }) => {
  const deleteCourse = useDelete(`api/courses/${courseId}/`, {
    onSuccess: () => {
      onClose();
      onSuccess();
    },
  });

  const handleDelete = () => {
    deleteCourse.mutate(courseId);
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this course?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CourseDelete;
