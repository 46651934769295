import React, { useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const zodiacSigns = [
  { name: 'Kos', icon: '♈' },
  { name: 'Bika', icon: '♉' },
  { name: 'Ikrek', icon: '♊' },
  { name: 'Rák', icon: '♋' },
  { name: 'Oroszlán', icon: '♌' },
  { name: 'Szűz', icon: '♍' },
  { name: 'Mérleg', icon: '♎' },
  { name: 'Skorpió', icon: '♏' },
  { name: 'Nyilas', icon: '♐' },
  { name: 'Bak', icon: '♑' },
  { name: 'Vízöntő', icon: '♒' },
  { name: 'Halak', icon: '♓' }
];

const DraggableItem = ({ name, icon }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'SIGN',
    item: { name, icon },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: '24px',
        fontWeight: 'bold',
        cursor: 'move',
        padding: '10px',
        margin: '5px',
        border: '1px solid black',
        display: 'inline-block',
      }}
    >
      {icon} {name}
    </div>
  );
};

const DropZone = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'SIGN',
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        height: '400px',
        width: '400px',
        border: '2px dashed gray',
        position: 'relative',
        backgroundColor: isOver ? 'lightblue' : 'white',
      }}
    >
      <h3 style={{ textAlign: 'center' }}>Dobózóna</h3>
    </div>
  );
};

const AstrologyGame = () => {
  const [droppedItems, setDroppedItems] = useState([]);

  const handleDrop = (item) => {
    setDroppedItems((prevItems) => [...prevItems, item]);
  };

  const hasWon = droppedItems.length === zodiacSigns.length;

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Asztrológiai Játék</h1>
        <div>
          {zodiacSigns.map((sign) => (
            <DraggableItem key={sign.name} name={sign.name} icon={sign.icon} />
          ))}
        </div>
        <DropZone onDrop={handleDrop} />
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <h3>Elhelyezett elemek</h3>
          {droppedItems.map((item, index) => (
            <div key={index} style={{ fontSize: '24px', margin: '5px' }}>
              {item.icon} {item.name}
            </div>
          ))}
        </div>
        {hasWon && <h2>Nyertél!</h2>}
      </div>
    </DndProvider>
  );
};

export default AstrologyGame;
