import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useFetching, usePost, usePatch } from '../../auth/apicalls';

const CourseForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState({ name: '', description: '' });
  const { data, isError, isLoading } = useFetching(`/api/courses/${id}/`);

  useEffect(() => {
    if (data) setCourse({ name: data.name, description: data.description });
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse(prevCourse => ({ ...prevCourse, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateCourse.mutate(course);
    } else {
      createCourse.mutate(course);
    }
  };

  const createCourse = usePost('/api/courses/', {
    onSuccess: () => navigate('/tanulokozpont'),
  });

  const updateCourse = usePatch(`/api/courses/${id}/`, {
    onSuccess: () => navigate('/tanulokozpont'),
  });

  return (
    <div>
      <h2>{id ? 'Edit Course' : 'Add Course'}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" value={course.name} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" name="description" value={course.description} onChange={handleChange} required />
        </Form.Group>
        <Button type="submit" className="mt-3">{id ? 'Update' : 'Create'}</Button>
      </Form>
    </div>
  );
};

export default CourseForm;
