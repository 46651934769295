import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { usePost } from '../../auth/apicalls';

const LevelForm = ({ show, handleClose, onSuccess, lessonId }) => {
    const { courseId } = useParams();
    const [level, setLevel] = useState({ level_number: '', necessary_level: '', points: '', description: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLevel(prevLevel => ({ ...prevLevel, [name]: value }));
    };

    const createLevel = usePost(`/api/courses/${courseId}/lessons/${lessonId}/levels/`, {
        onSuccess: () => {
            onSuccess();
            handleClose();
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        createLevel.mutate(level);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Level</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="level_number">
                        <Form.Label>Level Number</Form.Label>
                        <Form.Control type="number" name="level_number" value={level.level_number} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="necessary_level">
                        <Form.Label>Necessary Level</Form.Label>
                        <Form.Control type="number" name="necessary_level" value={level.necessary_level} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="points">
                        <Form.Label>Points</Form.Label>
                        <Form.Control type="number" name="points" value={level.points} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" name="description" value={level.description} onChange={handleChange} />
                    </Form.Group>
                    <Button type="submit" className="mt-3">Add Level</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default LevelForm;
