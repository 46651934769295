import React from 'react';
import EgyszeruAnalogiaLink from "./components/EgyszeruAnalogiaLink";
import OsszetettAnalogiaLink from "./components/OsszetettAnalogiaLink";
import { Button, Row, Col } from 'react-bootstrap';
import { useFetching } from "../auth/apicalls";
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"

function Analogialeszedo(props) {
    const { data: aaData, isError1, isLoading1} = useFetching("/api/" + props.endpoint + "/", false);
    const { data: aatData, isError2, isLoading2} = useFetching("/api/aanalogiak/", false);

    const analogiaheader = (
        <Row className='text-center'>
            {aatData && aatData.map((aanalogia, index) => (
                <Col key={index}>
                    <Button variant="outline-dark" href={"/" + aanalogia.enpoint_nev}>
                        {aanalogia.displayed_nev} ({aanalogia.count}) ({aanalogia.analogia_count})
                    </Button>
                </Col>
            ))}
        </Row>
    );

    return (
        <>
        {(isLoading1 || isLoading2) && <Loading size={60} />}
        {(isError1 || isError2) && <ErrorAlert show={isError1} text="" />}
        {aatData && aaData && (
        <div>
            {analogiaheader}
            <div className="m-3 row">
                {aaData.aanalogia_list.map((analogia, index) => (
                    props.egyszeru === 'true' ? (
                        <div className="col-md-4" key={index}>
                            <EgyszeruAnalogiaLink tarolo={props.endpoint} aanalogia={analogia}/>
                        </div>
                    ) : (
                        <div className="col-md-4 col-sm-6" key={index}>
                            <OsszetettAnalogiaLink tarolo={props.endpoint} aanalogia={analogia}/>
                        </div>
                    )
                ))}
            </div>
        </div>
                )}
                </>
    );
}

export const BolygokPage = () => {
    return <Analogialeszedo endpoint='bolygok' egyszeru='true'/>;
};

export const JegyekPage = () => {
    return <Analogialeszedo endpoint="jegyek" egyszeru='true'/>;
};

export const HazakPage = () => {
    return <Analogialeszedo endpoint="hazak" egyszeru='true'/>;
};

export const BJkPage = () => {
    return <Analogialeszedo endpoint='bolygokJegyekben' egyszeru="false"/>;
};

export const HJkPage = () => {
    return <Analogialeszedo endpoint="hazakJegyekben" egyszeru="false"/>;
};

export const BHkPage = () => {
    return <Analogialeszedo endpoint="bolygokHazakban" egyszeru="false"/>;
};

export const HUHkPage = () => {
    return <Analogialeszedo endpoint="hazakUraHazakban" egyszeru="false"/>;
};
