import React from 'react';
import { Table } from 'react-bootstrap';

const KonkretAnalogiaTulajdonsagai = ({ analogiaAdatok, hibasszempont }) => {

    return (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <td><b>Absztrakt analógia típus</b></td>
                    <td className={hibasszempont === 'aanalogia_besorolasi_hiba' ? 'text-danger' : ''}>{analogiaAdatok.aat}</td>
                </tr>
                <tr>
                    <td><b>Absztrakt analógia</b></td>
                    <td className={hibasszempont === 'aanalogia_besorolasi_hiba' ? 'text-danger' : ''}>{analogiaAdatok.aa}</td>
                </tr>
                <tr>
                    <td><b>Csoportnév</b></td>
                    <td className={hibasszempont === 'csoport_besorolasi_hiba' ? 'text-danger' : ''}>
                        {hibasszempont === '' ? analogiaAdatok.csoport.nev : analogiaAdatok.csoport}
                    </td>
                </tr>
                <tr>
                    <td><b>Analógia objektum (tartalom)</b></td>
                    <td className={hibasszempont === 'tartalmi_hiba' ? 'text-danger' : ''}>             {analogiaAdatok.tartalom}
                    </td>
                </tr>
                {hibasszempont === '' &&
                <>
                    <tr>
                        <td><b>Elért validációs pontszám</b></td>
                        <td>{analogiaAdatok.validacio}</td>
                    </tr>
                    <tr>
                        <td><b>Feltöltő felhasználó</b></td>
                        <td>@{analogiaAdatok.feltolto_neve}</td>
                    </tr>
                </>
                }
            </tbody>
        </Table>
    );
};

export default KonkretAnalogiaTulajdonsagai;
