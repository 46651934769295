import React, {  useContext } from 'react';
import AuthContext from '../../pages/userkezeles/AuthContext';
import {useFetching} from "../auth/apicalls"
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"

const Helyezetek = () => {
  const { user, isAdmin } = useContext(AuthContext);
  const { data:helyezetekData, isError, isLoading } = useFetching('/api/helyezettek/');

  return (
    <>
        {isLoading && <Loading size={60} />}
        {isError && <ErrorAlert show={isError} text="" />}
        {helyezetekData && (
        <div className="container text-center">
      <h1 className='mt-3 mb-5'>Helyezettek listája</h1>
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Felhasználó</th>
            <th scope="col">Pontok</th>
            <th scope="col">Szint</th>
          </tr>
        </thead>
        <tbody>
          {helyezetekData.map((profile, index) => (
            <tr key={profile.user_id} className={profile.user_id === user.user_id ? 'table-primary' : ''}>
              <th scope="row">{index + 1}.
                {isAdmin && profile.days_since_last_login}
                {isAdmin && " - "}
                {isAdmin && profile.days_since_date_joined}
              </th>
              <td>
                {profile.role === "Admin" ?
                  <svg width="16" height="16" fill="currentColor" className="mx-1" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                  </svg>
                  :
                  profile.role === "Szerkesztő" ?
                    <svg width="16" height="16" fill="currentColor" className="mx-1" viewBox="0 0 16 16">
                      <path d="M16 4.5a4.5 4.5 0 0 1-1.703 3.526L13 5l2.959-1.11q.04.3.041.61"/>
                      <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.5 4.5 0 0 0 11.5 9m-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376M3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                    </svg>
                    : ""
                }
                { profile.new &&
                <svg width="16" height="16" fill="currentColor" className="mx-1" viewBox="0 0 16 16">
                   <path fill-rule="evenodd" d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2 2 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224zM4.352 3.356a4 4 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5s-.226.496-.498.542c-.95.162-1.749.78-2.173 1.617a.6.6 0 0 1-.52.341c-.346 0-.599-.329-.457-.644"/>
               </svg>

                }
                {profile.username}
              </td>
              <td>{profile.pontszam}</td>
              <td>{profile.felhasznaloi_szint}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> )}
    </>

  );
};

export default Helyezetek;
