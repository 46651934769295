import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../userkezeles/AuthContext";
import KerdesValasz from "./KerdesValasz";
import {useFetching, usePost, usePatch} from "../auth/apicalls"
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"
import {showErrorAlert} from "../alert/ErrorAlert"

function GamePage() {
  const { id } = useParams();
  const [elertPont, setElertPont] = useState(null);
  const [answers, setAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(null);
  const navigate = useNavigate();
  let { user } = useContext(AuthContext);
  const { data: game, isError, isLoading } = useFetching(`/api/gyakorlo/${id}`);

  const { mutate: postUjGyakorloInditas} = usePost(`/api/gyakorlo/`, {
    onSuccess: (data) => {

      navigate(`/gyakorlo/${data.data.id}/`);
    },
    onError: (error) => {
        showErrorAlert({
            title: "Nem sikerült új gyakorló tesztet indítani!",
            text: error.response.data.user_error
          });
      },
  });

  const { mutate: patchEllenorzes} = usePatch(`/api/gyakorlo/${id}/`, {
    onSuccess: (data) => {
      navigate(`/gyakorlo/${data.data.id}/`);
    },
    onError: (error) => {
        showErrorAlert({
            title: "Nem sikerült ellenőrizni az eredményt!",
            text: error.response.data.user_error
          });
      },
  });

  const handlePlayAgainClick = async () => {
    setScore(0)
    setShowResults(false)
    setAnswers({})
    postUjGyakorloInditas({
      nev: game.gyakorloopcio.nev,
      max_pont: game.gyakorloopcio.max_pont,
      user: user.userid,
      kerdesek_szama: game.gyakorloopcio.kerdesek_szama,
      valaszlehetosegek_szama: game.gyakorloopcio.valaszlehetosegek_szama,
      gyakorlotipus: game.gyakorloopcio.gyakorlotipus,
      analogiatipus_szuro: game.gyakorloopcio.analogiatipus_szuro,
      csoport_szuro:game.gyakorloopcio.csoport_szuro
    })
  };

  const checkAnswers = async () => {
    setShowResults(true);
    const correctCount = Object.keys(answers).reduce((count, question) => {
      return answers[question] === game.kerdesvalaszok.find(item => item.kerdes === question).helyesvalasz
        ? count + 1
        : count;
    }, 0);
    const percentage = ((correctCount / game.kerdesvalaszok.length) * 100).toFixed(2);
    const elert_pont = percentage === '100.00' ? game.max_pont : 0;
    setScore(percentage);

    patchEllenorzes({
      id: game.id,
      max_pont: game.max_pont,
      eredmeny_szazalek: parseFloat(percentage),
      elert_pont: elert_pont,
    })
  };

  return (
    <>
    {isLoading && <Loading size={60} />}
    {isError && <ErrorAlert show={isError} text="" />}
    {game && (
    <div className="mt-4">
      <h2 className="mb-4 text-center">{game.nev}</h2>
      {game.eredmeny_szazalek !== null ? (
        <div className='text-left'>
          <p>Eredmény Százalék: {game.eredmeny_szazalek}</p>
          <p>Elért Pontszám: {elertPont}</p>
          <Button href="/gyakorloopciok" className="mx-1 btn btn-primary">
            Irány gyakorló főmenübe
          </Button>
        </div>
      ) : (
        <div className="card p-2 border-0">
          {game.kerdesvalaszok.map((kerdesvalasz, index) => (
            <KerdesValasz {...{kerdesvalasz, index, showResults, answers, setAnswers}}/>
          ))}
          <button onClick={checkAnswers} disabled={showResults} className="btn btn-primary">
            Válaszok ellenőrzése
          </button>
          {showResults && (
            <div className="mt-3">
              <div>
                <p>Eredmény: {score}%</p>
                <p>Megszerzett pontok: {game.elert_pont}</p>
                <Button href="/gyakorloopciok" className="mx-1 text-center">
                  Vissza a gyakorló főmenübe
                  <svg  width="20" height="20" fill="currentColor" class="mx-2" viewBox="0 0 20 20">
  <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
                </Button>
              </div>
              <div>
                <Button onClick={handlePlayAgainClick} className="mx-1 text-center btn-success mt-2">
                  Újra játszás
                  <svg width="20" height="20" fill="currentColor" class="mx-2" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>
                </Button>  
              </div>
            </div>
          )}
        </div>
      )}
    </div>        )}
    </>
  );
};




export default GamePage;
