import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap'; // Row és Col hozzáadva
import AuthContext from '../../pages/userkezeles/AuthContext'
import {useFetching} from "../auth/apicalls"
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"

const addDotToEndOfSentence = (sentence) => {
  const lastChar = sentence.trim().slice(-1); // Utolsó karakter kiválasztása, szóközök levágása
  if (lastChar !== '.' && lastChar !== '!' && lastChar !== '?') {
    return sentence.trim() + '.'; // Pont hozzáadása, ha szükséges
  }
  return sentence.trim(); // Ha már van pont, vagy más lezáró jel, visszatérünk a eredeti mondatra
};

const Lecke = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { data: leckeData, isError, isLoading } = useFetching(`/api/lecke/${id}/`);

  return (
    <>
    {isLoading && <Loading size={60} />}
    {isError && <ErrorAlert show={isError} text="" />}
    {leckeData && (
      leckeData.fejezet_szukseges_szint > user.felhasznaloi_szint ?
      <div className='text-center m-4'>
      <div className='m-5'></div>
        <b>
          Jelenleg a rendszer szerint {user.felhasznaloi_szint}. szinten állsz, azonban ehhez a leckéhez el kell érned a(z) {leckeData.fejezet_szukseges_szint}. szintet!
        </b>
        <p>
          Kattints a profilodra, hogy frissítsd az aktuális szinted, vagy nézd meg ott, mennyit szükséges még elérned, hogy elérhetsd ezt a leckét
        </p>
        <p>
          <Link to={`/leckefejezetek`} >
            <Button variant='primary' className='text-center'>Vissza a leckék főmenüjébe</Button>
          </Link>
        </p>
        <p>
          <Link to={`/profil`} >
            <Button variant='primary' className='text-center'>Profil megtekintése</Button>
          </Link>
        </p>
      </div>
      :
      <div className="container m-lg-5">
        <h1 className='text-center mt-3'>{leckeData.lecke.cim}</h1>
        <div className="m-lg-4 px-lg-4">
          {Object.entries(leckeData.lecke.tartalom).map(([bulletPoint, content]) => (
            <Card key={bulletPoint} className=" border-0">
              <Card.Body>
                <Card.Title className='my-2'>{bulletPoint}</Card.Title>
                {Object.entries(content).map(([key, value]) => (
                  <div key={key} className='mx-3'>
                    {Array.isArray(value) ? (
                      <ul>
                        {value.map((item, index) => (
                          <li key={index} className='mb-2'>{addDotToEndOfSentence(item)}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className='mb-2'>{addDotToEndOfSentence(value)}</p>
                    )}
                  </div>
                ))}
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className='text-center row'> {/* Row hozzáadva */}
          <p>Elérhető pontszám sikeres teszt után: {leckeData.lecke.pontszam}</p>
          <Link to={`/lecke/${leckeData.lecke.id}/test`}>
            <Button variant='success'>Teszt indítása</Button>
          </Link>
        </div>
        <hr/>
        <div className='text-center'>
        <Link to={`/leckefejezetek`} >
            <Button variant='primary' className='my-2 text-center'>Vissza a leckék főmenüjébe</Button>
          </Link>
        </div>
      </div>
  )}
        </>
  );
};

export default Lecke;
