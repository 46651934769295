import React, {useContext} from 'react';
import { Button } from 'react-bootstrap';
import { usePost } from '../../auth/apicalls';
import { useQueryClient } from "react-query";
import AuthContext from "../../userkezeles/AuthContext";


function CreateExerciseAndTestButton({ lessonId, levelId, exercisesAndTestsLength }) {
  const queryClient = useQueryClient();
  let {isSzerkeszto} = useContext(AuthContext);
  const { mutate: createExerciseAndTest } = usePost(`/api/lessons/${lessonId}/levels/${levelId}/create_exercise_and_test/`, {
    onSuccess: () => {
      // Refetch the exercises and tests after successfully creating a new one
      queryClient.invalidateQueries(`/api/lessons/${lessonId}/levels/${levelId}/get_exercises_and_tests/`);
    },
    onError: (error) => {
      alert("Failed to create exercise and test: " + error.response.data.detail);
    }
  });

  const handleCreateExerciseAndTest = () => {
    const newExerciseAndTest = {
      exercise: {
        title: "Fejlesztés alatt lévő szöveg",
        content: "...",
        yt_link: "---",
        order: exercisesAndTestsLength + 1
      },
      test: {
        title: "Fejlesztés alatt lévő tesztkérdések",
        description: "",
        questions: [
          {
            text: "?",
            order: 1,
            answers: [
              { text: "A", is_correct: true },
              { text: "B", is_correct: false }
            ]
          }
        ]
      }
    };

    createExerciseAndTest(newExerciseAndTest);
  };

  return (
    <>
        { isSzerkeszto &&
        <Button variant="primary" onClick={handleCreateExerciseAndTest}>
        Create New Exercise and Test
      </Button>

    }
    </>
  );
}

export default CreateExerciseAndTestButton;
