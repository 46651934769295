import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useFetching, usePost, usePatch } from '../../auth/apicalls';

const LevelForm = () => {
  const { courseId, lessonId, levelId } = useParams();
  const navigate = useNavigate();
  const [level, setLevel] = useState({ level_number: '', necessary_level: '', points: '', description: '' });
  const { data, isError, isLoading } = useFetching(levelId ? `/api/courses/${courseId}/lessons/${lessonId}/levels/${levelId}/` : null);

  useEffect(() => {
    if (data) setLevel({ level_number: data.level_number, necessary_level: data.necessary_level, points: data.points, description: data.description });
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLevel(prevLevel => ({ ...prevLevel, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (levelId) {
      updateLevel.mutate(level);
    } else {
      createLevel.mutate({ ...level });
    }
  };

  const createLevel = usePost(`/api/courses/${courseId}/lessons/${lessonId}/levels/`, {
    onSuccess: () => navigate(`/tanulokozpont/${courseId}/lecke`),
  });

  const updateLevel = usePatch(`/api/courses/${courseId}/lessons/${lessonId}/levels/${levelId}/`, {
    onSuccess: () => navigate(`/tanulokozpont/${courseId}/lecke`),
  });

  return (
    <div>
      <h2>{levelId ? 'Edit Level' : 'Add Level'}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="level_number">
          <Form.Label>Level Number</Form.Label>
          <Form.Control type="number" name="level_number" value={level.level_number} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="necessary_level">
          <Form.Label>Necessary Level</Form.Label>
          <Form.Control type="number" name="necessary_level" value={level.necessary_level} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="points">
          <Form.Label>Points</Form.Label>
          <Form.Control type="number" name="points" value={level.points} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" name="description" value={level.description} onChange={handleChange} />
        </Form.Group>
        <Button type="submit" className="mt-3">{levelId ? 'Update' : 'Create'}</Button>
      </Form>
    </div>
  );
};

export default LevelForm;
