import {Link} from 'react-router-dom';
import AuthContext from "../../userkezeles/AuthContext";
import React, {useContext} from 'react'
import {useParams} from 'react-router-dom';
import {usePost} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";
import { usePermissions } from '../../userkezeles/PermissionContext';

const AnalogiaErtekeles = ({ analogia, props }) => {
    let {user} = useContext(AuthContext)
    let {id} = useParams();
    const queryClient = useQueryClient();
    const { analogiaErtekelesJog } = usePermissions();


    const { mutate: postEertekeles} = usePost(`/api/analogiavalidalas`, {
        onSuccess: () => {
            queryClient.invalidateQueries(`/api/${props.aanalogia_endpoint}/${id}`);
        },
        onError: (error) => {
            showErrorAlert({
                title: "Nem sikerült értékelni az analógiát!",
                text: error.response.data.user_error
              });
        },
        });

    const handleLinkClickUpVote = async (analogia) => {
        postEertekeles({"analogia": analogia.id})
    };

    const isButtonDisabled = (analogia) => {
        if (user &&
            analogia.validalasok.some(elem => elem.ertekelo === user.userid)) return true
        return false
      };

    return (
        <>
        {analogiaErtekelesJog >= 5 ? (
            <Link className={`btn btn-outline-dark btn-sm 
                ${isButtonDisabled(analogia) ? 'disabled' : ''}`} to=""
                onClick={() => handleLinkClickUpVote(analogia)}>
                <svg width="13" height="13" fill="currentColor"
                    className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path
                        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                {analogia.validacio}
            </Link>
        ) : (
            <Link className={`btn btn-outline-dark btn-sm disabled
                ${isButtonDisabled(analogia) ? 'disabled' : ''}`} to=""
                onClick={() => handleLinkClickUpVote(analogia)}>
                <svg width="13" height="13" fill="currentColor"
                    className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path
                        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                {analogia.validacio}
            </Link>
            
        )}
    </>
    );
}

export default AnalogiaErtekeles;
