// src/components/HibajelentesSzavazat.js
import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContext from "../../userkezeles/AuthContext";
import { usePost } from "../../auth/apicalls";
import { showErrorAlert } from "../../alert/ErrorAlert";
import { useQueryClient } from "react-query";
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { usePermissions } from '../../userkezeles/PermissionContext';

const HibajelentesSzavazat = ({ hibajelentes }) => {
  let { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { hibajelentesJog } = usePermissions();

  const handleVote = async (voteType) => {
    postHibajelentesSzvazat({
      ertek: voteType === 'up' ? user.felhasznaloi_szint : -user.felhasznaloi_szint
    });
  };

  const { mutate: postHibajelentesSzvazat } = usePost(`/api/analogiahibajelentes/${hibajelentes.id}/szavazat/`, {
    onSuccess: () => {
      queryClient.invalidateQueries('/api/analogiahibajelentes/');
    },
    onError: (error) => {
      showErrorAlert({
        title: "Nem sikerült szavazni a hibajelentésben!",
        text: error.response.data.user_error
      });
    },
  });

  const isButtonDisabled = !user ||
    !hibajelentesJog ||
    user.userid === hibajelentes.ertekelo ||
    hibajelentes.szavazatok.includes(user.userid) ||
    hibajelentes.lezart_elfogadott != null;

  return (
    <div className="btn-group">
      <button
        className="btn btn-success"
        onClick={() => handleVote('up')}
        disabled={isButtonDisabled}
      >
        <FaThumbsUp />
      </button>
      <button
        className="btn btn-danger"
        onClick={() => handleVote('down')}
        disabled={isButtonDisabled}
      >
        <FaThumbsDown />
      </button>
    </div>
  );
};

export default HibajelentesSzavazat;
