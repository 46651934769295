import React, { useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContext from "../../userkezeles/AuthContext";
import {useDelete} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";
import { usePermissions } from '../../userkezeles/PermissionContext';

const HibajelentesTorles = ({hibajelentes}) => {
  let {user} = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { hibajelentesJog } = usePermissions();

  const handleDelete = async () => {
    deleteHibajelentes()
  };
  const { mutate: deleteHibajelentes} = useDelete(`/api/analogiahibajelentes/${hibajelentes.id}/`, {
        onSuccess: () => {
            queryClient.invalidateQueries('/api/analogiahibajelentes/');
        },
        onError: (error) => {
            showErrorAlert({
                title: "Nem sikerült törölni a hibajelentést!",
                text: error.response.data.user_error
            });
        },
    });


  return (
    <>
        {hibajelentesJog && user.userid === hibajelentes.ertekelo && (
        <button
        className="btn btn-danger mt-2"
        onClick={() => handleDelete()}
        disabled={hibajelentes.lezart_elfogadott != null}
        >
        Hibajelölés visszavonása
        </button>)
        }
    </>
  );
};

export default HibajelentesTorles;
