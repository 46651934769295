import React, { useEffect, useContext, useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import PontszamProgressBar from "./PontszamProgressBar";
import AuthContext from "../AuthContext";
import { Link } from 'react-router-dom';
import {useFetching} from "../../auth/apicalls"
import {Loading} from "../../alert/Loading"
import {ErrorAlert} from "../../alert/ErrorAlert"

const UserProfile = () => {
    const { user, updateToken } = useContext(AuthContext);
    const { data: gyakorlohistoryData, isError, isLoading } = useFetching('/api/gyakorlo/history/');

    useEffect(() => {
        updateToken();
    }, []);

    const [animatedScore, setAnimatedScore] = useState(0);
    useEffect(() => {
        let interval;
        const difference = user.pontszam - animatedScore;
        const duration = 4000; // 2 másodperc alatt töltődjön fel

        if (difference > 0) {
            const steps = Math.ceil((difference * 50) / duration); // 50 ms-enként frissítve
            interval = setInterval(() => {
                setAnimatedScore(prevScore => {
                    const newScore = prevScore + Math.ceil(difference / steps);
                    return newScore >= user.pontszam ? user.pontszam : newScore;
                });
            }, 50);
        }

        return () => clearInterval(interval);
    }, [user.pontszam, animatedScore]);

    return (
        <>
        {isLoading && <Loading size={60} />}
        {isError && <ErrorAlert show={isError} text="" />}
        {gyakorlohistoryData && (
        <Container>
            <h2 className='text-center my-2 p-2 card border-0'>
                Üdvözöllek <span style={{ color: 'orange' }}>{user.username}</span> a profilodban!
            </h2>

            <Row className="justify-content-center my-3">
                <Col>
                    <Row>
                        <Col>
                            <div className="value">
                                <h4>Pontszám:</h4>
                                <p>{user.pontszam}</p>
                            </div>
                            <div className="level">
                                <h4>Szint:</h4>
                                <p>{user.felhasznaloi_szint}</p>
                            </div>
                            {/* <div className="level">
                                <h4>Emailes jóváhagyás:</h4>
                                <p>{user.email_verified}</p>
                            </div> */}
                        </Col>
                    </Row>
                    <Row className="progress-bar">
                        <Col>
                            <PontszamProgressBar pontszam={animatedScore} szint={user.felhasznaloi_szint} />
                        </Col>
                    </Row>
                </Col>
                {/* {user.email_verified ? (
                            <Badge pill bg="success" className="m-1">
                                <i className="bi bi-check-circle"></i> Emailes jóváhagyás rendben
                            </Badge>
                        ) : (
                            <>
                                <Badge pill bg="danger" className="m-1">
                                    <i className="bi bi-exclamation-circle"></i> Emailes jóváhagyás szükséges
                                </Badge>
                                <span className="text-muted">(Az email már ki lett küldve)</span>
                            </>
                        )} */}
            </Row>

            <Row className="mt-5">
                <Col>
                    <h2>Analógia gyakorló előzmények</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Visszatekintés</th>
                                <th>Eredmény</th>
                                <th>Pontszám</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gyakorlohistoryData.map((entry, index) => (
                                <tr key={index}>
                                    <td><Link to={`/gyakorlo/${entry.id}`}>{entry.nev}</Link></td>
                                    <td>{Math.floor(entry.eredmeny_szazalek)}%</td>
                                    <td>{entry.elert_pont}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
        )}
        </>
    );
};

export default UserProfile;
