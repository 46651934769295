import {createContext, useCallback, useEffect, useState} from 'react'
import jwt_decode from "jwt-decode";
import {useNavigate} from 'react-router-dom'


const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {
    let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    let [loading, setLoading] = useState(true)

    let [isSzerkeszto] = useState(user && true ? user.role === "Admin" || user.role === "Szerkesztő" : false)
    let [isAdmin] = useState(user && true ? user.role === "Admin" : false)
    const navigate = useNavigate()

    let loginUser = async (e)=> {
        e.preventDefault()
        let response = await fetch(`/api/token/`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'username':e.target.username.value, 'password':e.target.password.value})
        })
        let data = await response.json()
        if(response.status === 200){
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
            navigate("/")
        }else{
            alert('Helytelen felhasználónév jelszó kombináció!')
        }
    }

    const registerUser = async (e) => {
        e.preventDefault();

        const response = await fetch(`/api/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'username': e.target.username.value, 'password': e.target.password.value, 'email': e.target.email.value})
        });
        let data = await response.json()
    if(response.status === 200){
    //     setAuthTokens(data)
    //     setUser(jwt_decode(data.access))
    //     localStorage.setItem('authTokens', JSON.stringify(data))
            loginUser(e);
            alert("Sikeres regisztráció és automatikus belépés!")
            // navigate("/login")
    }else{
        if (data.error === "Username already exists.") {
            alert('Ez a felhasználónév már foglalt! Válassz másikat!')
        }
        else if (data.error === "Email already exists.") {
            alert('Ez az email cím már használatban van! Válassz másikat!')
        }        
        else {
            alert('Valami hiba történt. Ha a problémát nem sikerül megoldani keresd az admint: molnaradam8466@gmail.com')
        }           
    };
}

    let logoutUser = useCallback(() => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate("/login")
    }, [navigate])


    let updateToken = useCallback(async ()=> {
        let response = await fetch(`/api/token/refresh/`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens?.refresh}`
            },
            body:JSON.stringify({'refresh':authTokens?.refresh})
        })
        let data = await response.json()
        if (response.status === 200){
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
        } else if (response.status === 401) {
            if (data.code === "token_not_valid"){
                console.log("Blacklisted token");
            } else {
                alert("Az automatikus bejelentkezés nem sikerült, kérlek lépj be újra!")
                logoutUser()
            }
        } else {
            console.log("Nem 200 response status!:", response.status );
        }
        if(loading){
            setLoading(false)
        }
    }, [authTokens, loading, logoutUser]);

    let contextData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser,
        registerUser:registerUser,
        updateToken:updateToken,
        isAdmin:isAdmin,
        isSzerkeszto:isSzerkeszto
    }

    useEffect(()=> {

        if(loading){
            updateToken()
        }

        let fourMinutes = 1000 * 60 * 4

        let interval =  setInterval(()=> {
            if(authTokens){
                updateToken()
            }
        }, fourMinutes)
        return ()=> clearInterval(interval)

    }, [authTokens, loading, updateToken])

    return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
}
