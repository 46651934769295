import React from "react";
import Swal from "sweetalert2";

export const ErrorAlert = ({ show, text }) => {
  React.useEffect(() => {
    if (show) {
      Swal.fire({
        icon: "error",
        title: "Hoppá! Valami hiba történt!",
        text,
        confirmButtonColor: "#b14",
        didOpen: () => {
          const modalContainer = Swal.getContainer();
          if (modalContainer) {
            modalContainer.style.zIndex = "999";
          }
        },
      });
    }
  }, [show, text]);

  return null;
};

export const showErrorAlert = async ({ title, text }) => {
  Swal.fire({
    title,
    text,
    icon: "error",
    confirmButtonColor: "#b14",
    didOpen: () => {
      const modalContainer = Swal.getContainer();
      if (modalContainer) {
        modalContainer.style.zIndex = "999";
      }
    },
  });
};


export default ErrorAlert;
