import React, { useState } from 'react';


const CreateFejezetForm = ({ addFejezet }) => {
    const [cim, setCim] = useState('');
    const [szuksegesSzint, setSzuksegesSzint] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      addFejezet({ cim, szukseges_szint: parseInt(szuksegesSzint) });
      setCim('');
      setSzuksegesSzint('');
    };
  
    return (
      <div>
        <h2>Create New Fejezet</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              className="form-control"
              value={cim}
              onChange={(e) => setCim(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Required Level:</label>
            <input
              type="number"
              className="form-control"
              value={szuksegesSzint}
              onChange={(e) => setSzuksegesSzint(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Create</button>
        </form>
      </div>
    );
  };
  
  export default CreateFejezetForm;
  