import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Loading = ({ size = "40px" }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Spinner animation="border" role="status" variant="primary" size={size}>
      {/* Ez fog forogni benne, jó lenne beletenni valamit */}
        <span className="secondary-color"></span>
      </Spinner>
    </div>
  );
};

export default Loading;
