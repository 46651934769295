export const check_username = (username) => {
    return /^[a-z0-9]+$/.test(username) && username.length <= 15 && username.length > 3;
};

export const check_email = (email) => {
    const basicEmailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!basicEmailPattern.test(email)) {
        return false;
    }

    return true;
};

export const check_password = (password) => {
    if (password.length < 8) { // 8 nak számít
        return false;
    }

    const hasLowerCase = /[a-záéóüöőúűí]/.test(password);
    const hasUpperCase = /[A-ZÁÉÓÜÖŐÚŰÍ]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    return hasLowerCase && hasUpperCase && hasNumber;
};

