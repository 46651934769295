import React from 'react'
import {useParams} from 'react-router-dom';
import EgyszeruAanalogiaLinkCsoportHeader from './components/EgyszeruAanalogiaLinkCsoportHeader';
import CsoportositottAnalogiak from './components/CsoportositottAnalogiak';
import { useFetching } from "../auth/apicalls";
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"

const AbsztraktAnalogia = (props) => {
    let {id} = useParams();
    const { data: analogiatarolo_data} = useFetching("/api/" + props.aanalogia_endpoint + "/", false);
    const { data: readAnalogia_data, isError, isLoading } = useFetching(`/api/${props.aanalogia_endpoint}/${id}`, false);

    return (
        <>
        {isLoading && <Loading size={60} />}
        {isError && <ErrorAlert show={isError} text="Nem sikerült betölteni az analógia adatbázis adatokat!" />}
        {analogiatarolo_data && (
        <div>
            <h1 className="text-center mb-4">{readAnalogia_data?.nevid && readAnalogia_data.nevid.charAt(0).toUpperCase() + readAnalogia_data.nevid.slice(1)}</h1>
            <EgyszeruAanalogiaLinkCsoportHeader aanalogiaList={analogiatarolo_data.aanalogia_list} props={props} />
            <div className="row mb-5">
                {readAnalogia_data &&
                    Object.entries(readAnalogia_data).map(([csoport, analogiak]) => (
                        <CsoportositottAnalogiak {...{csoport, analogiak, props} }
                        aanalogiaNev={analogiatarolo_data.aanalogianev}
                        />
                    ))}
            </div>
        </div>)}
    </>
    );
}

export const JegyPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="jegyek"/>;
}
export const HazPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="hazak"/>;
}
export const BolygoPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="bolygok"/>;
}

export const BJPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="bolygokJegyekben"/>;
}
export const BHPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="bolygokHazakban"/>;
}
export const HJPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="hazakJegyekben"/>;
}
export const HUHPage = () => {
    return <AbsztraktAnalogia aanalogia_endpoint="hazakUraHazakban"/>;
}
