import React from 'react'
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const OsszetettAnalogiaLink = ({ tarolo, aanalogia }) => {
    const astrologySymbols = {
        nap: "☉", // Sun
        hold: "☽", // Moon
        merkúr: "☿", // Mercury
        vénusz: "♀", // Venus
        mars: "♂", // Mars
        jupiter: "♃", // Jupiter
        szaturnusz: "♄", // Saturn
        uránusz: "♅", // Uranus
        neptun: "♆", // Neptune
        pluto: "♇", // Pluto
        kos: "♈", // Aries
        bika: "♉", // Taurus
        ikrek: "♊", // Gemini
        rák: "♋", // Cancer
        oroszlán: "♌", // Leo
        szűz: "♍", // Virgo
        mérleg: "♎", // Libra
        skorpió: "♏", // Scorpio
        nyilas: "♐", // Sagittarius
        bak: "♑", // Capricorn
        vízöntő: "♒", // Aquarius
        halak: "♓"  // Pisces
    };

    const getSymbol = (name) => astrologySymbols[name.toLowerCase()] || "";

    let displayText = "";

    if (aanalogia.alap_haz) {
        let alap = aanalogia.alap_haz;
        let kettes = aanalogia.ura_melyik_hazban;
        displayText = `${alap}.ház ura - ${kettes}. házban`;
    } else if (!aanalogia.bolygo) {
        displayText = `${aanalogia.jegy} - ${aanalogia.haz}. ház`;
    } else if (!aanalogia.haz) {
        displayText = `${aanalogia.jegy} - ${aanalogia.bolygo}`;
    } else if (!aanalogia.jegy) {
        displayText = `${aanalogia.bolygo} - ${aanalogia.haz}. házban`;
    }

    const parts = displayText.split(" - ");
    const symbolText = parts.map(part => getSymbol(part) + " " + part).join(" - ");

    return (
        <Card className="course-card">
            <Card.Body>
                <Card.Title className='text-center'>
                    <Link to={`/${tarolo}/${aanalogia.id}`}>
                        <h5>{symbolText}</h5>
                    </Link>
                </Card.Title>
            </Card.Body>
        </Card>
    );
}

export default OsszetettAnalogiaLink;
