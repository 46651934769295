import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useFetching, usePost, usePatch } from '../../auth/apicalls';

const LessonForm = () => {
  const { courseId, lessonId } = useParams();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState({ title: '', description: '', order: 0, badge: null });
  const [badges, setBadges] = useState([]);

  const { data: fetchedLesson, isError, isLoading } = useFetching(lessonId ? `/api/courses/${courseId}/lessons/${lessonId}/` : null);
  const { data: fetchedBadges } = useFetching('/api/badges/');

  useEffect(() => {
    if (fetchedLesson) {
      setLesson({
        title: fetchedLesson.title,
        description: fetchedLesson.description,
        order: fetchedLesson.order,
        badge: fetchedLesson.badge ? fetchedLesson.badge.id : '',
      });
    }
    if (fetchedBadges) {
      setBadges(fetchedBadges);
    }
  }, [fetchedLesson, fetchedBadges]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLesson(prevLesson => ({ ...prevLesson, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (lessonId) {
      updateLesson.mutate({ ...lesson, badge: lesson.badge || null });
    } else {
      createLesson.mutate({ ...lesson, course: courseId, badge: lesson.badge || null });
    }
  };

  const createLesson = usePost(`/api/courses/${courseId}/lessons/`, {
    onSuccess: () => navigate(`/tanulokozpont/${courseId}/lecke`),
  });

  const updateLesson = usePatch(`/api/courses/${courseId}/lessons/${lessonId}/`, {
    onSuccess: () => navigate(`/tanulokozpont/${courseId}/lecke`),
  });

  return (
    <div>
      <h2>{lessonId ? 'Edit Lesson' : 'Add Lesson'}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" name="title" value={lesson.title} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" name="description" value={lesson.description} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="order">
          <Form.Label>Order</Form.Label>
          <Form.Control type="number" name="order" value={lesson.order} onChange={handleChange} required />
        </Form.Group>
        <Form.Group controlId="badge">
          <Form.Label>Badge</Form.Label>
          <Form.Control as="select" name="badge" value={lesson.badge} onChange={handleChange}>
            <option value="">Select a badge</option>
            {badges.map((badge) => (
              <option key={badge.id} value={badge.id}>{badge.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button type="submit" className="mt-3">{lessonId ? 'Update' : 'Create'}</Button>
      </Form>
    </div>
  );
};

export default LessonForm;
