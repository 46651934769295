import React from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, Row, Col } from 'react-bootstrap';

const EgyszeruAnalogiaLink = ({ tarolo, aanalogia }) => {
    let postfix = "";
    let szimbolum = "";

    const astrologySymbols = {
        nap: "☉", // Sun
        hold: "☽", // Moon
        merkúr: "☿", // Mercury
        vénusz: "♀", // Venus
        mars: "♂", // Mars
        jupiter: "♃", // Jupiter
        szaturnusz: "♄", // Saturn
        uránusz: "♅", // Uranus
        neptun: "♆", // Neptune
        pluto: "♇", // Pluto
        kos: "♈", // Aries
        bika: "♉", // Taurus
        ikrek: "♊", // Gemini
        rák: "♋", // Cancer
        oroszlán: "♌", // Leo
        szűz: "♍", // Virgo
        mérleg: "♎", // Libra
        skorpió: "♏", // Scorpio
        nyilas: "♐", // Sagittarius
        bak: "♑", // Capricorn
        vízöntő: "♒", // Aquarius
        halak: "♓"  // Pisces
    };

    if (tarolo === "hazak") {
        postfix = ". ház";
    } else if (tarolo === "jegyek" || tarolo === "bolygok") {
        szimbolum = astrologySymbols[aanalogia.nevid.toLowerCase()] || "";
    }

    return (
        <Link to={`/${tarolo}/${aanalogia.id}`}>
            <Card className="course-card">
                <Card.Body>
                    <Card.Title className='text-center'>
                        {szimbolum} {aanalogia.nevid}{postfix}
                    </Card.Title>
                </Card.Body>
            </Card>
        </Link>
    );
}

export default EgyszeruAnalogiaLink;
