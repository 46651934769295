import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { useFetching } from '../../auth/apicalls';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import CourseDelete from './CourseDelete';
import './CourseList.css';
import AuthContext from "../../userkezeles/AuthContext";

const CourseList = () => {
  let {isSzerkeszto, isAdmin} = useContext(AuthContext);
  const { data: courses, isLoading, isError } = useFetching('api/courses', false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCourseId(null);
  };

  const handleDeleteSuccess = () => {
    window.location.reload();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>A tanulóközpontot nem sikerült betölteni!</div>;

  return (
    <div className="course-list-container">
      <h1 className="course-list-title">Tanulóközpont</h1>
      <p className="text-secondary text-center">Válassz egy szimpatikus témakört!</p>
      {isAdmin && (
        <Link to="/tanulokozpont/new" className="btn btn-success mb-3">
          <FaPlus /> Add Course
        </Link>
      )}
      <Row>
        {courses.map(course => (
          <Col md={4} key={course.id} className="mb-4">
            <Card className="course-card">
            <Link to={`/tanulokozpont/${course.id}/lecke`} className="course-link">
              <Card.Body>
                <Card.Title>
                    {course.name}
                </Card.Title>
                <Card.Text>{course.description}</Card.Text>
              </Card.Body>
            </Link>
            {isSzerkeszto && (
                  <div className="course-actions">
                    <Link to={`/tanulokozpont/${course.id}`} className="btn btn-info mr-2">
                      <FaEdit /> Edit
                    </Link>
                    {isAdmin && (
                    <Button variant="danger" onClick={() => handleDeleteClick(course.id)}>
                      <FaTrash /> Delete
                    </Button>)}
                  </div>
                )}
            </Card>
          </Col>
        ))}
      </Row>
      <CourseDelete
        show={showModal}
        handleClose={handleCloseModal}
        courseId={selectedCourseId}
        onSuccess={handleDeleteSuccess}
      />
    </div>
  );
};

export default CourseList;
