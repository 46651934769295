import React, { useContext, useState } from 'react';
import AuthContext from './AuthContext';
import { check_username, check_password, check_email } from '../../utils/validateloginregister';
import { Nav, Form } from "react-bootstrap";

const RegistrationPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [usernameValid, setUsernameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false); // Checkbox állapot

    const handleUsernameChange = (event) => {
        const username = event.target.value;
        const formattedUsername = username.trim().toLowerCase();
    
        const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        if (alphanumericRegex.test(formattedUsername) && formattedUsername.length <= 15 || username == '') {
            setUsername(formattedUsername);
            setUsernameValid(check_username(formattedUsername));
        }
    };

    const handleEmailChange = (event) => {
        const email = event.target.value
        setEmail(email);
        setEmailValid(check_email(email));
    };

    const handlePasswordChange = (event) => {
        const pwd = event.target.value

        setPassword(pwd);
        setPasswordValid(check_password(pwd));
    };

    const { registerUser } = useContext(AuthContext);

    const handleRegisterSubmit = (event) => {
        event.preventDefault(); // Form alapértelmezett eseményének megakadályozása

        if (usernameValid && passwordValid && emailValid && termsChecked) {
            registerUser(event);
        } else {
            alert("Helytelen felhasználónév, email vagy jelszó, vagy nem fogadtad el az adatkezelési nyilatkozatot!");
        }
    };

    return (
        <>
            <h1 className="text-center m-5">Regisztráció</h1>
            <form onSubmit={handleRegisterSubmit} className="input-group m-3 row">
                <div className='py-2'>
                    <input
                        type="text"
                        placeholder="Felhasználónév"
                        name="username"
                        value={username}
                        className={`form-control ${!usernameValid ? 'is-invalid' : 'is-valid'}`}
                        onChange={handleUsernameChange}
                    />
                    {!usernameValid &&
                        <div>
                            A felhasználónév csak ékezet nélküli kisbetűket és számokat tartalmazhat! (Maximum 15 minimum 4 karakterben)
                        </div>
                    }
                </div>
                <div className='py-2'>
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        className={`form-control ${!emailValid ? 'is-invalid' : 'is-valid'}`}
                        onChange={handleEmailChange}
                    />
                    {!emailValid &&
                        <div>
                            A megadott email cím formátuma jelenleg nem megfelelő!
                        </div>
                    }
                </div>
                <div className='py-2'>
                    <input
                        type="password"
                        name="password"
                        placeholder="Jelszó"
                        value={password}
                        className={`form-control ${!passwordValid ? 'is-invalid' : 'is-valid'}`}
                        onChange={handlePasswordChange}
                    />
                    {!passwordValid &&
                        <div>
                            A jelszónak minimum 8 karaktert kell tartalmaznia, amik között lennie kell számnak, kis és nagybetűnek!
                        </div>
                    }
                </div>

                <Form.Group className="py-2" controlId="termsCheckbox">
                    <Form.Check
                        type="checkbox"
                        label={
                            <>
                                Elfogadom az <a href="/adatkezelesi-nyilatkozat" target="_blank">adatkezelési nyilatkozatot</a>
                            </>
                        }
                        checked={termsChecked}
                        onChange={(e) => setTermsChecked(e.target.checked)}
                        isInvalid={!termsChecked} // Megjelöli az első checkboxot, ha nincs bejelölve
                        feedback="A regisztrációhoz el kell fogadni az adatkezelési nyilatkozatot!"
                    />
                </Form.Group>

                <button type="submit" className="btn btn-outline-primary">Regisztráció</button>
            </form>

            <div className='pt-5 text-center text-secondary'>
                <p>Már van fiókod? Lépj be itt:</p>
                <Nav.Link href="/login" className="text-center text-primary">
                    Belépés
                </Nav.Link>
            </div>
            {/* <div className='pt-3 text-center text-secondary'>
                <p>Elfelejtetted a jelszavad?</p>
                <Nav.Link href="/elfelejtett-jelszo" className="text-center text-primary">
                    Új jelszó igénylése
                </Nav.Link>
            </div> */}
        </>
    );
};

export default RegistrationPage;
