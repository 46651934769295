import React, { useState } from 'react';
import {  Form } from 'react-bootstrap';
import {useFetching} from "../../auth/apicalls"


const HibajelentesJavitas = ({ hibatipus, analogiaAdatok, hibaLeiras, setHibaLeiras, javitottertek, setJavitottertek,eredetiErtek , setEredetiErtek }) => {
  const [selectedCsoportNev, setSelectedCsoportNev] = useState([]);
  const { data: csoportOptions } = useFetching(`/api/analogia/${analogiaAdatok.id}/get_csoportok/`);


  const handleJavitottertekChange = (event) => {
    setJavitottertek(event.target.value);

    if (hibatipus === "csoport_besorolasi_hiba") {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const nev = selectedOption.getAttribute('data-nev');
        setSelectedCsoportNev(nev);
    } 
  };

  const handleHibaLeirasChange = (event) => {
    const inputText = event.target.value;
    setHibaLeiras(inputText);
  };
    
  const renderContent = () => {
    switch (hibatipus) {
      case 'tartalmi_hiba':
        setEredetiErtek(analogiaAdatok.tartalom)
        return (
          <Form.Group controlId="formField">
              <Form.Control
              type="text"
              value={javitottertek}
              placeholder="a javított analógia helye" 
              onChange={handleJavitottertekChange}
              />
          </Form.Group>
        );
      case 'csoport_besorolasi_hiba':
        setEredetiErtek(analogiaAdatok.csoport.nev)
        return (
          <Form.Group controlId="csoportDropdown">
          <Form.Label>Helyes csoport, amire javítani szeretnéd: </Form.Label>
          <Form.Control as="select" onChange={handleJavitottertekChange}>
              let 
          {csoportOptions.map((option) => (
              <option key={option.nev} value={option.id} data-nev={option.nev}>
                  {option.nev}
              </option>
          ))}
          </Form.Control>
          </Form.Group>
        );
      case 'aanalogia_besorolasi_hiba':
        setEredetiErtek(analogiaAdatok.aa)
        return (
          <Form.Group controlId="analogiaCharfield">
              <Form.Label>Helyes absztrakt analógia, amire javítani szeretnéd:</Form.Label>
              <Form.Control
                type="text" 
                placeholder="a javított analógia helye" 
                onChange={handleJavitottertekChange}
                />
          </Form.Group>
        );
      case 'torlendo_analogia':
        setEredetiErtek(`${analogiaAdatok.aa} ${analogiaAdatok.csoport.nev} ${analogiaAdatok.tartalom}`)
        setJavitottertek('true')
        return (
            <p>
                <b>Ha a hibajelentés el lesz fogadva, az analógia törlődni fog</b>
            </p>
        );
      default:
        return null;
    }
  };

    return (
        <Form>
            <h3 className='mt-4'>Hiba javítása</h3>
        <Form.Group controlId="hibaLeiras">
        <h5>Mondd el miért tartod hibásnak a tárgyalt analógiát:</h5>
          <Form.Control
            as="textarea"
            rows={5}
            value={hibaLeiras}
            placeholder="Leírás: Miért rossz a régi, milyen változtatás szükséges?" 
            onChange={handleHibaLeirasChange}
          />
        </Form.Group>

        <h5 className='mt-4'>Mondd el milyen analógiára szeretnéd cserélni a helytelent:</h5>
        {renderContent()}
        { hibatipus !== 'torlendo_analogia' &&
        <>
          <p className='mt-3'>Ez a módosítás fog történni: </p>
          <b>{eredetiErtek} 
                          <svg width="16" height="16" fill="currentColor" className="bi bi-arrow-right mx-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
              </svg>
              {hibatipus === 'csoport_besorolasi_hiba' ? selectedCsoportNev : javitottertek}
          </b>
        </>
        }
        
      </Form>
    );
};

export default HibajelentesJavitas;
