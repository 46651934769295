import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you have React Router installed and configured

// Import your image
import backgroundImage from '../styles/hatter.jpeg';
import { EmailIkon, InstaIkon, RolemIkon, YouTubeIkon } from '../utils/Ikonok';

const Footer = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const szin = "white"
  const classszin = " text-white "

  // Define text styles
  const textStyle = {
    color: 'white',
    textShadow: '3px 5px 5px 2px #266497' // Adjust the shadow as needed
  };
  return (
    <footer className="py-5 text-center" style={backgroundStyle}>
      <hr className="py-5" />
      <div className="container mb-3">
        <div className="text-center mb-3 row">
          <a className="link-dark col" href="https://instagram.com/asztrovaros" style={textStyle}>
            <InstaIkon/>
            <br />
            <b style={textStyle}>Instagram</b>
          </a>
          {/* <a className="link-dark col" href="https://www.tiktok.com/@asztrovaros">
          <svg  width="30" height="30" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg>
            <br />
            <b>TikTok</b>
          </a> */}
          <div className="col" style={textStyle}>
            <EmailIkon/>
            <br />
            <b style={textStyle}>Email</b>
            <div style={textStyle}>asztrovaros@gmail.com</div>
          </div>
          <a className="link-dark col" href="https://www.youtube.com/watch?v=tIaQLKMg7AQ&list=PLOYQ3UX09xAgbfU6cJ09pDe8FD-I-NbcX&index=1" style={textStyle}>
            <YouTubeIkon/>
            <br />
            <b style={textStyle}>YoutTube</b>
          </a>
          <Link to="/rolam/" className="link-dark col" style={textStyle}>
            <RolemIkon/>
            <br />
            <b style={textStyle}>Rólam</b>
          </Link>
        </div>
      </div>
      <div className="text-center p-4 m-5">
        <a className="text-reset fw-bold" href="https://asztrovaros.hu/">
          <h4 style={textStyle}>asztrovaros.hu</h4>
        </a>
      </div>
      <div className="py-5 mt-5"></div>
      <div className="py-5 mt-5"></div>
      <div className="py-5 mt-5"></div>
    </footer>
  );
};

export default Footer;
