import React, { useState, useContext } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { usePost } from '../../auth/apicalls';
import QuestionItem from './QuestionItem';
import Confetti from 'react-confetti';
import AuthContext from "../../userkezeles/AuthContext";
import { animated, useSpring } from '@react-spring/web';

function ViewTest({ setMessage, updateQuestion, testState }) {
  const [showConfetti, setShowConfetti] = useState(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  let { user } = useContext(AuthContext);

  const handleTestSuccess = () => {
    setMessage('Sikeres teszt! Gratulálok! :)');
  };

  const handleTestFailure = () => {
    setMessage('Sajnos a teszt sikertelen, próbáld újra!');
  };

  const additionalAnimationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1300 }
  });

  const { mutate: submitTest } = usePost(`/api/tests/${testState.id}/submit_test/`, {
    onSuccess: (response) => {
      if (response.data.success) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 4000); // Stop confetti after 4 seconds
        handleTestSuccess();
      } else {
        alert('Test submission failed. Please try again.');
      }
    },
    onError: (error) => {
      handleTestFailure();
    },
  });

  const handleSubmit = () => {
    if (!user) {
      setShouldLogin(true);
    } else {
      const answers = testState.questions.reduce((acc, question) => {
        acc[question.id] = question.answers
          .filter((answer) => answer.is_correct)
          .map((answer) => answer.id);
        return acc;
      }, {});
      submitTest({ answers });
    }
  };

  return (
    <div className='my-3 mb-5'>
      {showConfetti && <Confetti />}
      <h1 className="text-center my-3">Teszteld a tudásod!</h1>
      <p className='text-secondary text-center'>
        Jelöld be azokat a négyzeteket, amikhez tartozó válaszokat helyesnek tartod! <br/> Vigyázz lehet több jó megoldás is, lehet az összes helyes és helytelen is!
      </p>
      <Form.Group>
        <p className="bg-warning text-center">{testState.description}</p>
      </Form.Group>
      <Form>
        {testState.questions.map((question, questionIndex) => (
          <QuestionItem
            key={questionIndex}
            question={question}
            questionIndex={questionIndex}
            isEditing={false}
            updateQuestion={updateQuestion}
          />
        ))}
        <div className="d-flex justify-content-center">
          {shouldLogin ? (
            <div className='row'>
              <div className='col-12 text-center'>
                <b>Jelenleg nem vagy belépve!</b>
                <br />
                <br />
                <b>A teszt ellenőrzéséhez belépés/regisztráció szükséges!</b>
              </div>
              <animated.div style={additionalAnimationProps} className="col-6">
                <Alert variant="info" className="animated-alert">
                  <Button variant="warning" href="/register">Regisztráció</Button>
                </Alert>
              </animated.div>
              <animated.div style={additionalAnimationProps} className="col-6">
                <Alert variant="info" className="animated-alert">
                  <Button variant="success" href="/login">Belépés</Button>
                </Alert>
              </animated.div>
            </div>
          ) : (
            <Button variant="success" onClick={handleSubmit}>
              Teszt ellenőrzése
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

export default ViewTest;
