import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom'; // Import useParams to get parameters from the URL
import Hibatupusvalasztas from './Hibatipusvalasztas'; // Import useParams to get parameters from the URL
import KonkretAnalogiaTulajdonsagai from './KonkretAnalogiaTulajdonsagai';
import HibajelentesJavitas from './HibajelentesJavitas';
import { useNavigate } from 'react-router-dom';
import {useFetching, usePost} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import {Loading} from "../../alert/Loading"
import {ErrorAlert} from "../../alert/ErrorAlert"

const UjHibajelentes = () => {
    const [analogiaAdatok, setAnalogiaAdatok] = useState(null);
    const [hibatipus, setHibatipus] = useState(null);
    const [hibaLeiras, setHibaLeiras] = useState('');
    const [javitottertek, setJavitottertek] = useState('');
    const [eredetiErtek, setEredetiErtek] = useState('');
    const navigate = useNavigate();
    const hibasszempont = '';
    const { analogiaid } = useParams();
    const { data: analogiaAdatokFetch, isError, isLoading } = useFetching(`/api/analogiak/${analogiaid}/`);

    const { mutate: postUjHibajelentes} = usePost(`/api/analogiahibajelentes/`, {
        onSuccess: () => {
            navigate("/hibajelentestargyalo")
        },
        onError: (error) => {
            showErrorAlert({
                title: "Nem sikerült haibajelentést létrehozni!",
                text: error.response.data.user_error
              });
          },
      });


    useEffect(() => {
        const fetchAnalogiaAdatok = async () => {
            if (analogiaAdatokFetch) setAnalogiaAdatok(analogiaAdatokFetch);
        };
        fetchAnalogiaAdatok();
    }, [analogiaid, analogiaAdatokFetch]);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setHibatipus(value);
    };

    let analogia_hibajelentes = async () => {
        postUjHibajelentes({
            "analogia": analogiaid,
            "leiras": hibaLeiras,
            [hibatipus]: javitottertek,
            "eredeti_ertek": eredetiErtek
    })
    }

    return (
        <>
        {isLoading && <Loading size={60} />}
        {isError && <ErrorAlert show={isError} text="" />}
        {analogiaAdatokFetch && (
        <div className={"col-12"}>
            {analogiaAdatok && (
                <div className="row">
                    <h1 className='text-center m-3'>Analógia hiba bejelentés</h1>
                    <h3 className='mt-4 mb-3'>Analógia tulajdonságai</h3>
                    
                    <KonkretAnalogiaTulajdonsagai {...{analogiaAdatok, hibasszempont}}/>
                    <Hibatupusvalasztas {...{hibatipus, handleInputChange}}/>
                    <HibajelentesJavitas {...{hibatipus, analogiaAdatok, hibaLeiras, setHibaLeiras, javitottertek, setJavitottertek, eredetiErtek, setEredetiErtek}}/>

                     <p className='text-center text-muted mt-5 mb-2'>Ha jóváhagyod a hibajelentést ez az analógia át fog kerülni az analógia hibajelentés tárgyalóba, ahol mindenki szavazhat, hogy tényleg hibás-e az analógia</p>
                    <Button onClick={analogia_hibajelentes} variant="danger"
                    disabled={hibatipus === null ||
                              (javitottertek == '' && hibatipus !== 'torlendo_analogia') ||
                              hibaLeiras == '' }>
                        Hibabejelentés készítése!
                    </Button>
                </div>
            )}
        </div>
     )}</>
    );
}

export default UjHibajelentes;
