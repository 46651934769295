import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import React, {useState, useContext} from 'react'
import {useDelete} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";
import {useParams} from 'react-router-dom';
import AuthContext from "../../userkezeles/AuthContext";
import { usePermissions } from '../../userkezeles/PermissionContext';


const TorlesGomb = ({ analogia, props }) => {
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();
    let {id} = useParams();
    let {user} = useContext(AuthContext)
    const { analogiaLetrehozasJog } = usePermissions();


    const { mutate: deleteAnalogia} = useDelete(`/api/analogiak/${analogia.id}/delete`, {
        onSuccess: () => {
            queryClient.invalidateQueries(`/api/${props.aanalogia_endpoint}/${id}`);
            setShowModal(false);
        },
        onError: (error) => {
            showErrorAlert({
                title: "Nem sikerült törölni az analógiát!",
                text: error.response.data.user_error
              });
        },
    });

    const handleLinkClickTrash = () => {
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        deleteAnalogia();
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    return (
        <>
        {analogiaLetrehozasJog && user.userid === analogia.feltolto && (
        <Link className="btn btn-outline-dark btn-sm" to=""
                onClick={() => handleLinkClickTrash()}>
            <svg width="16" height="16" fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16">
                <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                <path
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
            </svg>
        </Link>)}

        <Modal show={showModal} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Biztosan törölni szeretnéd ezt az analógiát?</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body className='text-center'><b>{analogia.tartalom}</b></Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelDelete}>
                        Mégse
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Törlés
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TorlesGomb;
