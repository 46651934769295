import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from "../userkezeles/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css'; // Importáljuk a Bootstrap stílusokat

const Iranyzatok = () => {
  const [iranyzatok, setIranyzatok] = useState([]);
  const { user } = useContext(AuthContext);

  const handleJoinIrányzat = async (iranyzatId) => {
    try {
      await axios.post(`/api/iranyzat/${iranyzatId}/join/`, { user_id: user.userid });
      const updatedIranyzatok = iranyzatok.map(iranyzat => {
        if (iranyzat.id === iranyzatId) {
          return { ...iranyzat, tagok: [...iranyzat.tagok, user.userid] };
        }
        return iranyzat;
      });
      setIranyzatok(updatedIranyzatok);
    } catch (error) {
      console.error('Error joining Iranyzat:', error);
    }
  };

  const handleLeaveIrányzat = async (iranyzatId) => {
    try {
      await axios.post(`/api/iranyzat/${iranyzatId}/leave/`, { user_id: user.userid });
      const updatedIranyzatok = iranyzatok.map(iranyzat => {
        if (iranyzat.id === iranyzatId) {
          const updatedTagok = iranyzat.tagok.filter(tag => tag !== user.userid);
          return { ...iranyzat, tagok: updatedTagok };
        }
        return iranyzat;
      });
      setIranyzatok(updatedIranyzatok);
    } catch (error) {
      console.error('Error leaving Iranyzat:', error);
    }
  };

  useEffect(() => {
    const fetchIranyzatok = async () => {
      try {
        const response = await axios.get('/api/iranyzat/');
        setIranyzatok(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchIranyzatok();
  }, []);

  return (
    <div className="container">
      <h1 className="my-4 text-center">Asztrológia iskolák és irányzatok</h1>
      <p className='text-center'>Csatlakozz akár több asztrológiai irányzathoz hogy kisebb csoportob belül is építhessétek a közösséget!</p>

      <ul className="list-group">
        {iranyzatok.map(iranyzat => (
          <li key={iranyzat.id} className="list-group-item">
            <h4 className="font-weight-bold">{iranyzat.nev}</h4>
            <p>{iranyzat.leiras}</p>
            {iranyzat.tagok.includes(user.userid) ? (
              <button className="btn btn-danger" onClick={() => handleLeaveIrányzat(iranyzat.id)}>Kilépés</button>
            ) : (
              <button className="btn btn-success" onClick={() => handleJoinIrányzat(iranyzat.id)}>Csatlakozás</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Iranyzatok;
