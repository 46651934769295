import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { usePut } from '../../auth/apicalls';
import QuestionItem from './QuestionItem';

function EditTest({ testState, setTestState, updateQuestion, isEditing }) {

  const handleDescriptionChange = (e) => {
    setTestState({
      ...testState,
      description: e.target.value,
    });
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      text: '',
      order: testState.questions.length + 1,
      answers: [{ text: '', is_correct: false }],
    };
    setTestState({
      ...testState,
      questions: [...testState.questions, newQuestion],
    });
  };

  const { mutate: saveTest } = usePut(`/api/tests/${testState.id}/`, {
    onSuccess: () => {
      alert('Test saved successfully');
    },
    onError: (error) => {
      alert('Failed to save test: ' + error.response.data.detail);
    },
  });

  const handleSave = () => {
    saveTest(testState);
  };

  return (
    <div>
      <Form.Group>
          <Form.Control
            as="textarea"
            value={testState.description}
            onChange={handleDescriptionChange}
          />
      </Form.Group>
      <Form>
        {testState.questions.map((question, questionIndex) => (
          <QuestionItem
            key={questionIndex}
            question={question}
            questionIndex={questionIndex}
            isEditing={isEditing}
            updateQuestion={updateQuestion}
          />
        ))}
          <>
            <Button variant="secondary" onClick={handleAddQuestion}>
              Add Question
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Test
            </Button>
          </>
      </Form>
    </div>
  );
}

export default EditTest;
