import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {usePost} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";
import {useParams} from 'react-router-dom';
import { usePermissions } from '../../userkezeles/PermissionContext';


const AnalogiaCsoport = ({ csoport, props, aanalogiaNev }) => {
    let {id} = useParams();
    const queryClient = useQueryClient();
    let [showTextAreaAtCsoport, setShowTextAreaAtCsoport] = useState(null)
    let [ujAnalogiaTartalom, setUjAnalogiaTartalom] = useState("")
    const { posztLetrehozasJog } = usePermissions();
    const { mutate: postAnalogia} = usePost(`/api/analogiakeszites`, {
        onSuccess: () => {
            queryClient.invalidateQueries(`/api/${props.aanalogia_endpoint}/${id}`);
        },
        onError: (error) => {
            showErrorAlert({
                title: "Nem sikerült létrehozni az analógiát!",
                text: error.response.data.user_error
              });
        },
        });
    
    const handleLinkClickAdd = (csoport) => {
        setShowTextAreaAtCsoport(csoport)
    };

    const handleTextareaChange = (event) => {
        setUjAnalogiaTartalom(event.target.value);
    };
    
    let handlesSubmitCreate = async (csoportnev) => {
        postAnalogia({
            "analogiatipus": aanalogiaNev,
            'csoportnev': csoportnev,
            'aanalogia_id': id,
            'tartalom': ujAnalogiaTartalom,
        });
        setShowTextAreaAtCsoport(null);
    };
    
    return (
        <h3 className="text-center p-2">
            {csoport}
            {posztLetrehozasJog && (
                <Link className="btn btn-outline-success btn-sm mx-3" to=""
                        onClick={() => handleLinkClickAdd(csoport)}>
                    <svg width="16" height="16" fill="currentColor"
                            className="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                </Link>)}
            {showTextAreaAtCsoport === csoport && (
                <>
                <textarea
                    onChange={handleTextareaChange}
                    style={{width: '90%', fontSize: '14px'}}
                />
                <Button onClick={() => handlesSubmitCreate(csoport)}>
                    Létrehozás
                </Button>
                </>
            )}
        </h3>
    );
}

export default AnalogiaCsoport;
