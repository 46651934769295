import React from 'react';

const Rolam = () => {
  return (
    <>
      <div className="container py-3">
        <div className="p-4 p-md-5 mb-4 rounded bg-dark row">
          <div className="col-md-8 px-0">
            <h2 className="text-white">Molnár Ádám</h2>
            <p className="lead my-3 text-white">
              Jelenleg programozóként dolgozom, emellett hobbiként ezt a weboldalt fejlesztgetem szabadidőmben.<br></br>
              Takács Tibornál kezdtem asztrológiát tanulni 2018 szeptemberben, ami mélységesen átírta a gondolkodásmódom.
              <br />A menyasszonyom Kapi Laura, szeretett kisfiam anyukája, rengeteget támogat a program fejlesztésében.
              <br/>
              Fontosnak tartom, hogy az információ és a lehetőség mindenki számára nyitott legyen minél közérthetőbb módon.
              
            </p>

          </div>
          {/* <div className="col-md-4 px-0 text-center">
            <img src={require('{path_to_your_static_folder}/adam.png')} className="rounded" alt="Kép Rólam" style={{ maxWidth: '300px', width: '100%' }} />
          </div> */}
        </div>
      </div>

      <div className="container py-3">
        <div className="p-4 p-md-5 mb-4 rounded bg-dark row">
          {/* <div className="col-md-4 px-0 text-center">
            <img src={require('{path_to_your_static_folder}/keplet.png')} className="rounded" alt="Kép Rólam" style={{ maxWidth: '300px', width: '100%' }} />
          </div> */}

          <div className="col-md-8 px-0">
            <h2 className="text-white">Asztrováros</h2>
            <p className="lead my-3 text-white">
              2021 áprilisban kezdtem el fejleszteni egy asztrológia alkalmazást,
              ami mindössze annyit tudott, hogy összefüggéseket, hozott létre bolygók, házak és jegyek között.
              Néhány hónap fejlesztésmentes idő után megérett bennem a gondolat, hogy szakdolgozatot tudnék készíteni ebből.
              Ahogy telt az idő egyre több és több ötletem keletkezett és egyre több fejlesztést tudtam megvalósítani.
              <br />
              A programot elsősorban mások segítése érdekében hoztam létre. Jelenleg egy közösségi felületként tudom elképzelni,
              ahol profi és kezdő asztrológusok és asztrológia iránt érdeklődők is megtalálják helyüket.
            </p>
          </div>
        </div>
      </div>

      <div className="container py-3">
        <div className="p-4 p-md-5 mb-4 rounded bg-dark row">
          <div className="col-md-12 px-0">
            <h4 className="text-white">Elérhetőségek</h4>
            <p className="lead my-3 text-white">
              <b className="text-white">Email:</b> asztrovaros@gmail.com <br />
              Nyitott vagyok együttműködési öteletkre. Kérésre szívesen fejlesztek újabb funkciókat. Bármilyen támogatást köszönök.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rolam;
