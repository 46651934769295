import React from 'react';

const Hibatipusvalasztas = ({ hibatipus, handleInputChange }) => {

    return (
        <div className="col-12">
            <h3 className="mt-4 mb-3">Analógia hibájának típusa</h3>
            <label className="d-block mb-2">
                <input
                    type="radio"
                    className="mx-2"
                    name="hibatipus"
                    value="csoport_besorolasi_hiba"
                    checked={hibatipus === 'csoport_besorolasi_hiba'}
                    onChange={handleInputChange}
                />
                <b>Csoport besorolási hiba</b>
                <p className="mx-4">
                    Az analógia helyes, de rossz csoportba lett sorolva
                    <br />Például testrész helyett szervnek kellene lennie
                </p>
            </label>
            <label className="d-block mb-2">
                <input
                    type="radio"
                    className="mx-2"
                    name="hibatipus"
                    value="aanalogia_besorolasi_hiba"
                    checked={hibatipus === 'aanalogia_besorolasi_hiba'}
                    onChange={handleInputChange}
                />
                <b>Absztrakt analógia besorolási hiba</b>
                <p className="mx-4">
                    Az analógia tartalma és csoportja helyes, de rossz absztrakt analógiába lett sorolva
                    <br />Például mars helyett nap analógiának kellene lennie
                </p>
            </label>
            <label className="d-block mb-2">
                <input
                    className="mx-2"
                    type="radio"
                    name="hibatipus"
                    value="tartalmi_hiba"
                    checked={hibatipus === 'tartalmi_hiba'}
                    onChange={handleInputChange}
                />
                <b>Tartalmi hiba</b>
                <p className="mx-4">
                    Az absztrakt analógia és csoportja helyes, de helytelen a tartalma
                    <br />Például helyesírási hiba, vagy nem elég pontos/helyes a megfogalmazás
                </p>
            </label>
            <label className="d-block">
                <input
                    className="mx-2"
                    type="radio"
                    name="hibatipus"
                    value="torlendo_analogia"
                    checked={hibatipus === 'torlendo_analogia'}
                    onChange={handleInputChange}
                />
                <b>Törlendő analógia</b>
                <p className="mx-4">
                    Értelmezhetetlen, vagy egyszerre több szempont dolog sem stimmel
                    <br /> Például egy é betű magában, sértő, nem analógiának szánt, vagy egy helyesírási hibákkal rosszul besorolt analógia
                </p>
            </label>
        </div>
    );
};

export default Hibatipusvalasztas;
