import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContext from "../../userkezeles/AuthContext";
import {usePost} from "../../auth/apicalls"
import {showErrorAlert} from "../../alert/ErrorAlert"
import { useQueryClient } from "react-query";

const HirFeltoltes = () => {
  const [newPostCim, setNewPostCim] = useState('');
  const [newPostSzoveg, setNewPostSzoveg] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [tipus, setTipus] = useState(''); // Új állapot a választott "tipus" értéknek
  let { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const { mutate: postHir} = usePost(`/api/hir/`, {
      onSuccess: () => {
          queryClient.invalidateQueries("/api/hir/");
      },
      onError: (error) => {
          showErrorAlert({
              title: "Nem sikerült létrehozni a posztot!",
              text: error.response.data.user_error
            });
      },
  });

  const handlePost = async () => {
      const formData = new FormData();
      formData.append('cim', newPostCim);
      formData.append('szoveg', newPostSzoveg);
      formData.append('user', String(user.userid));
      formData.append('tipus', tipus); // Hozzáadva a "tipus" értéket

      if (imageFile) {
        formData.append('image', imageFile);
      }
      postHir(formData)
  };  

  return (
    <>
      <div className="form-group text-center">
        <button className="btn btn-primary" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Összecsukás' : 'Új poszt hozzáadása'}
        </button>
      </div>
      {expanded && (
        <>
          <div className="row">
            <div className="col-md-12 my-1">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cím"
                  value={newPostCim}
                  onChange={(e) => setNewPostCim(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 my-1">
              <div className="form-group">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Bővebb leírás a posztról"
                  value={newPostSzoveg}
                  onChange={(e) => setNewPostSzoveg(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 my-1">
              <div className="form-group">
                <select
                  className="form-control"
                  value={tipus}
                  onChange={(e) => setTipus(e.target.value)}
                >
                  <option value="">Válassz egy kategóriát...</option>
                  <option value="elvi">Elvi kérdések</option>
                  <option value="gyakorlati">Gyakorlati kérdések</option>
                  <option value="altalanos">Általános kérdések</option>
                  <option value="mem">Vicces</option>
                  <option value="hirdetesek">Hirdetések</option>
                  {(user.role === "Admin" || user.role === "Szerkesztő") && (
                    <option value="kozhir">Közérdekű hír</option>
                  )
                  }
                </select>
              </div>
            </div>
          </div>
          {/* <div className="form-group my-2">
            <div className="custom-file-input">
              <input
                type="file"
                className="form-control"
                id="imageUpload"
                onChange={handleImageChange}
              />
              <label className="custom-file-label" htmlFor="imageUpload">
                Válassz ki egy megosztani kívánt képet (nem kötelező)
              </label>
            </div>
          </div> */}
          <div className="form-group text-center">
          <button className="btn btn-success" onClick={handlePost} disabled={!newPostCim || !newPostSzoveg || !tipus}>
            Poszt közzététele
          </button>
          </div>
        </>
      )}
    </>
  );
};

export default HirFeltoltes;
