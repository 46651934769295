import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Hibajelentesgomb from "../analogiadb/components/HibajelentesGomb"

function KerdesValasz({kerdesvalasz, index, showResults, answers, setAnswers}) {

    const handleAnswerSubmit = (question, selectedAnswer) => {
        setAnswers({
            ...answers,
            [question]: selectedAnswer,
        });
        };

  return (

        <div key={index} className="mb-4">
            <div className="card p-1 text-center position-relative border-0">
            <div className="position-absolute top-0 end-0 mt-1 me-1">
                <Hibajelentesgomb analogia_id={kerdesvalasz.aanalogiaid}/>
            </div>
            <i>{index + 1}. Kérdés {showResults && (
                <span>
                {answers[kerdesvalasz.kerdes] === kerdesvalasz.helyesvalasz ? (
                    <FaCheckCircle className="text-success ml-2" />
                ) : (
                    <FaTimesCircle className="text-danger ml-2" />
                )}
                </span>
            )}</i>
            <b>{kerdesvalasz.kerdes}</b>
            </div>

            <div className="row justify-content-center mt-3">
            {kerdesvalasz.valaszlehetosegek.map((answer, ansIndex) => (
                <div key={ansIndex} className="col-6 col-md-3 mb-3">
                <div
                    className={`card rounded px-2 ${
                    showResults && answer === kerdesvalasz.helyesvalasz ? 'bg-success' : ''
                    }`}
                >
                    <div className="form-check">
                    <input
                        type="radio"
                        id={`answer-${index}-${ansIndex}`}
                        name={`answer-${index}`}
                        value={answer}
                        onChange={() => handleAnswerSubmit(kerdesvalasz.kerdes, answer)}
                        disabled={showResults}
                        className="form-check-input"
                    />
                    <label
                        htmlFor={`answer-${index}-${ansIndex}`}
                        className="form-check-label"
                    >
                        {answer}
                    </label>
                    </div>
                </div>
                </div>
            ))}
            </div>
            <hr></hr>
        </div>
        
  );
};




export default KerdesValasz;
