import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

function QuestionItem({ question, questionIndex, isEditing, updateQuestion }) {
  const handleCheckboxChange = (answerIndex) => {
    const updatedAnswers = [...question.answers];
    updatedAnswers[answerIndex] = {
      ...updatedAnswers[answerIndex],
      is_correct: !updatedAnswers[answerIndex].is_correct,
    };

    updateQuestion(questionIndex, { ...question, answers: updatedAnswers });
  };

  const handleQuestionTextChange = (e) => {
    updateQuestion(questionIndex, { ...question, text: e.target.value });
  };

  const handleAnswerTextChange = (e, answerIndex) => {
    const updatedAnswers = [...question.answers];
    updatedAnswers[answerIndex] = {
      ...updatedAnswers[answerIndex],
      text: e.target.value,
    };

    updateQuestion(questionIndex, { ...question, answers: updatedAnswers });
  };

  const handleAddAnswer = () => {
    const updatedAnswers = [
      ...question.answers,
      { text: '', is_correct: false },
    ];
    updateQuestion(questionIndex, { ...question, answers: updatedAnswers });
  };

  const handleDeleteAnswer = (answerIndex) => {
    const updatedAnswers = question.answers.filter(
      (_, index) => index !== answerIndex
    );
    updateQuestion(questionIndex, { ...question, answers: updatedAnswers });
  };

  const handleDeleteQuestion = () => {
    updateQuestion(questionIndex, null); // Pass null to indicate deletion
  };

  return (
    <Form.Group className='my-3'>
      {isEditing ? (
        <>
          <Form.Label>Question {questionIndex + 1}</Form.Label>
          <Form.Control
            type="text"
            value={question.text}
            onChange={handleQuestionTextChange}
          />
          {question.answers.map((answer, answerIndex) => (
            <Row key={answerIndex}>
              <Col>
                <Form.Control
                  type="text"
                  value={answer.text}
                  onChange={(e) => handleAnswerTextChange(e, answerIndex)}
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Correct"
                  checked={answer.is_correct}
                  onChange={() => handleCheckboxChange(answerIndex)}
                />
              </Col>
              <Col>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteAnswer(answerIndex)}
                >
                  Delete Answer
                </Button>
              </Col>
            </Row>
          ))}
          <Button variant="secondary" onClick={handleAddAnswer}>
            Add Answer
          </Button>
          <Button variant="danger" onClick={handleDeleteQuestion}>
            Delete Question
          </Button>
        </>
      ) : (
        <>
          <h4>{question.text}</h4>
          {question.answers.map((answer, answerIndex) => (
            <Form.Check
              key={answerIndex}
              type="checkbox"
              label={answer.text}
              checked={answer.is_correct}
              onChange={() => handleCheckboxChange(answerIndex)}
            />
          ))}
        </>
      )}
      <hr />
    </Form.Group>
  );
}

export default QuestionItem;
