import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDelete } from '../../auth/apicalls';
import {useParams} from 'react-router-dom';

const DeleteConfirmation = ({ show, handleClose, lessonId, onSuccess }) => {
  let {courseId} = useParams();

  const deleteItem = useDelete(`/api/courses/${courseId}/lessons/${lessonId}/`, {
    onSuccess: () => {
      onClose();
      onSuccess();
    },
  });

  const handleDelete = async () => {
    deleteItem.mutate();
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this {lessonId ? 'lesson' : 'course'}?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
