import React, { useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { usePatch } from '../../auth/apicalls';
import AuthContext from "../../userkezeles/AuthContext";

function Exercise({ exercise }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedExercise, setEditedExercise] = useState(exercise);
  let {isSzerkeszto} = useContext(AuthContext);

  const { mutate: patchExercise } = usePatch(`/api/exercises/${editedExercise.id}/`, {
    onSuccess: () => {
      setIsEditing(false);
    },
    onError: (error) => {
      alert("Failed to update exercise: " + error.response.data.detail);
    }
  });

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedExercise({ ...editedExercise, [name]: value });
  };

  const handleSaveClick = async () => {
    patchExercise(editedExercise);
  };

  // Function to extract YouTube video ID from a URL
  const getYouTubeId = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  const youTubeId = getYouTubeId(editedExercise.yt_link);

  return (
    <>
      <h1 className='text-center my-3'>{editedExercise.title}</h1>
      {!isEditing ? (
        <>
          <ReactMarkdown>{editedExercise.content}</ReactMarkdown>
          <div className='text-center my-4'>
            {youTubeId && (
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={`https://www.youtube.com/embed/${youTubeId}`}
                  allowFullScreen
                  title={editedExercise.title}
                ></iframe>
              </div>
            )}
          </div>
          {isSzerkeszto &&
          <div className='text-center my-4'>
            <Button variant="primary" onClick={handleEditClick}>
              Edit
            </Button>
          </div>
          }
        </>
      ) : (
        <Row>
          <Col md={6}>
            <h3 className='text-center'>Preview</h3>
            <ReactMarkdown>{editedExercise.content}</ReactMarkdown>
          </Col>
          <Col md={6}>
            <h3 className='text-center'>Edit Content</h3>
            <Form>
              <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={editedExercise.title}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formContent">
                <Form.Label>Content</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  name="content"
                  value={editedExercise.content}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formYtLink">
                <Form.Label>YouTube Link</Form.Label>
                <Form.Control
                  type="text"
                  name="yt_link"
                  value={editedExercise.yt_link}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formOrder">
                <Form.Label>Order</Form.Label>
                <Form.Control
                  type="number"
                  name="order"
                  value={editedExercise.order}
                  onChange={handleChange}
                />
              </Form.Group>
              <div className='text-center my-4'>
                <Button variant="primary" onClick={handleSaveClick}>
                  Save
                </Button>
                <Button variant="secondary" onClick={handleEditClick}>
                  Cancel
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Exercise;
