import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import Test from './Test';
import Exercise from './Exercise';
import { useFetching } from '../../auth/apicalls';
import CreateExerciseAndTestButton from './CreateExerciseAndTestButton';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import Finish from './Finish'; // Import the Finish component
import './LevelExercise.css'; // Import the CSS file

function LevelExercise() {
  const { courseId ,lessonId, levelId } = useParams();
  const [exercisesAndTests, setExercisesAndTests] = useState([]);
  const { data, loading, error } = useFetching(`/api/lessons/${lessonId}/levels/${levelId}/get_exercises_and_tests/`, false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (data) {
      setExercisesAndTests(data);
    }
  }, [data]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < exercisesAndTests.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(currentIndex + 1); // Move to Finish component
    }
    setMessage('');
  };

  const handleBack = () => {
    setCurrentIndex(currentIndex - 1);
    setMessage('');
  };

  const currentItem = exercisesAndTests[currentIndex];

  return (
    <Container className="level-exercise-container">
      {loading && <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}
      {error && <Alert variant="danger">Error: {error.message}</Alert>}
      {message && <Alert variant={message.includes('Gratulálok') ? 'success' : 'danger'}>{message}</Alert>}
      {currentIndex < exercisesAndTests.length && currentItem && (
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="exercise-wrapper">
              <div className="exercise-content">
                {currentItem.type === 'exercise' ? (
                  <Exercise exercise={currentItem} />
                ) : (
                  <Test
                    test={currentItem}
                    setMessage={setMessage}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between mt-4">
                <Button variant="secondary" onClick={handleBack} disabled={currentIndex === 0}>
                  <FaArrowLeft /> <div className='mx-3'>Vissza</div>
                </Button>
                <Button variant="info" onClick={handleNext}>
                  <div className='mx-3'>{currentIndex === exercisesAndTests.length - 1 ? 'Befejezés' : 'Következő'}</div><FaArrowRight />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {currentIndex === exercisesAndTests.length && <Finish {...{courseId, handleBack}}/>}
      <div className="create-button-wrapper">
        <CreateExerciseAndTestButton
          lessonId={lessonId}
          levelId={levelId}
          exercisesAndTestsLength={exercisesAndTests.length}
        />
      </div>
    </Container>
  );
}

export default LevelExercise;
