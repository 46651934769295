import AnalogiaErtekeles from './AnalogiaErtekeles';
import TorlesGomb from './TorlesGomb';
import HibajelentesGomb from './HibajelentesGomb';
import React from 'react'

import AnalogiaCsoport from "./AnalogiaCsoport";

const CsoportositottAnalogiak = ({ csoport, analogiak, props, aanalogiaNev}) => {
    function choosebackgroundcolor(analogia) {
        if (analogia.validacio === 0) return {backgroundColor: `rgba(100, 100, 100, 0.2)`};
        if (analogia.validacio < 20) return {backgroundColor: `rgba(100, 200, 100, ${analogia.validacio/20})`};
        else {
            const gradientDirection = 'to right'; // Change the direction as needed
            const startColor = 'rgba(200, 170, 120, 1)';
            const endColor = 'rgba(120, 200, 120, 1)';
            return {background: `linear-gradient(${gradientDirection}, ${startColor}, ${endColor})`,
        }
    };
    }

    return (
        <div key={csoport} className="col-md-4 col-sm-12 card border-0 mb-5">
            <div className='card-title border-0'>
                <AnalogiaCsoport {...{csoport, props, aanalogiaNev}}/>
            </div>        
            
            {analogiak.map((analogia) => {
                let jelentett_hiba = analogia.hibajelentesek.length > 0 ? true : false;
                return (
                <div className='card m-1 p-1 border-0' style={choosebackgroundcolor(analogia)}>
                    <div className="row" key={analogia.id} >
                    <div className="col-8 text-center d-flex align-items-center justify-content-center">
                        {analogia.tartalom}
                        </div>
                        <div className={"col-4 text-center"}>
                            <div className="btn-group mx-1 col" role="group">
                                <AnalogiaErtekeles {...{analogia, props}}/>
                                <HibajelentesGomb analogia_id={analogia.id} jelentett_hiba={jelentett_hiba}/>
                            </div>
                            <div className="btn-group col" role="group"> 
                                <TorlesGomb analogia={analogia} props={props} />
                            </div>
                        </div>
                    </div>
                </div>
                );
            })}     
        </div>
    );
}

export default CsoportositottAnalogiak;
