import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddLeckeModal = ({ show, handleCloseModal, handleCreateLecke, newLeckeCim, setNewLeckeCim }) => {
  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Új Lecke Hozzáadása</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Lecke Címe</Form.Label>
          <Form.Control
            type="text"
            placeholder="Lecke címe"
            value={newLeckeCim}
            onChange={(e) => setNewLeckeCim(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Mégse
        </Button>
        <Button variant="primary" onClick={handleCreateLecke}>
          Mentés
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLeckeModal;
