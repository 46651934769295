import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import AuthContext from "../../userkezeles/AuthContext";
import { useNavigate } from 'react-router-dom';



const Szemelyek = () => {
  const [selectedTab, setSelectedTab] = useState('sajat');
  const [szemelyek, setSzemelyek] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate(); // Use useNavigate hook for navigation



  const fetchData = async (endpoint) => {
    try {
      let response = null;
      if (endpoint === "sajat"){
        response = await axios.post(`/api/szemely/${endpoint}/`, { user_id: user.userid });
      } else if (endpoint === "nyilvanos") {
        response = await axios.get(`/api/szemely/${endpoint}/`, {

        });
      } else {
        console.error("Nincs ilyen edpoint #607");
      }
      
      setSzemelyek(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(selectedTab);
  }, [selectedTab, setSelectedTab, fetchData]);

  const handleRowClick = (szemely) => {
    // Use history to navigate to the SzemelyDetailsPage when a row is clicked
    navigate(`/api/szemely/${szemely.id}`);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${selectedTab === 'sajat' ? 'active' : ''}`}
            onClick={() => setSelectedTab('sajat')}
          >
            Saját Személyek
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${selectedTab === 'nyilvanos' ? 'active' : ''}`}
            onClick={() => setSelectedTab('nyilvanos')}
          >
            Nyilvános Személyek
          </button>
        </li>
      </ul>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tulajdonos Neve</th>
            <th>Lathatosag</th>
            <th>Neme</th>
            <th>Leiras</th>
            <th>Keszites Ideje</th>
          </tr>
        </thead>
        <tbody>
          {szemelyek.map((szemely) => (
            <tr  key={szemely.id} onClick={() => handleRowClick(szemely)}>
              <td>{szemely.tulajdonos_neve}</td>
              <td>{szemely.lathatosag}</td>
              <td>{szemely.neme}</td>
              <td>{szemely.leiras}</td>
              <td>{szemely.keszites_ideje}</td>
 
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Szemelyek;
