import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import AuthContext from '../../pages/userkezeles/AuthContext'
import CreateFejezetForm from './CreateFejezet';
import UpdateFejezetForm from './UpdateFejezet';
import FejezetElemek from './FejezetElemek';
import {useFetching} from "../auth/apicalls"

const FejezetList = () => {
  const [fejezetList, setFejezetList] = useState([]);
  const { user } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editFejezetId, setEditFejezetId] = useState(null);
  const { data: fejezetListData} = useFetching('api/fejezet/get_fejezet_by_user/');

  useEffect(() => {
    const fetchFejezetList = async () => {
      if (fejezetListData) setFejezetList(fejezetListData);
    };
    fetchFejezetList();
  }, [user.user_id, fejezetListData]);

  const addFejezet = async (newFejezet) => {
    try {
      const response = await axios.post('/api/fejezet/', newFejezet);
      setFejezetList([...fejezetList, response.data]);
    } catch (error) {
      console.error('Error adding Fejezet:', error);
    }
  };

  const updateFejezet = async (fejezetId, updatedFejezet) => {
    try {
      const response = await axios.put(`/api/fejezet/${fejezetId}/`, updatedFejezet);
      setFejezetList(fejezetList.map((fejezet) => (fejezet.id === fejezetId ? response.data : fejezet)));
      setIsEditing(false);
      setEditFejezetId(null);
    } catch (error)
    {
      console.error('Error updating Fejezet:', error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditFejezetId(null);
  };
  console.log(user.role);
  return (
    <div className="mt-4">
      <h1 className="mb-5 text-center">Asztrováros leckék</h1>
      {(user.role === "Admin" || user.role === "Szerkesztő") &&
      <CreateFejezetForm addFejezet={addFejezet} />
  }
      <FejezetElemek {...{fejezetList, setFejezetList, setIsEditing, setEditFejezetId}}/>
      {(user.role === "Admin" || user.role === "Szerkesztő") &&
      <>
      {isEditing && (
        <UpdateFejezetForm
          fejezet={fejezetList.find((fejezet) => fejezet.id === editFejezetId)}
          updateFejezet={updateFejezet}
        />
      )}
      {isEditing && (
        <Button variant="secondary" onClick={handleCancelEdit}>
          Cancel Edit
        </Button>
      )}
      </>
}

    </div>
  );
};

export default FejezetList;
