import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Az asztrológiai jegyek
const zodiacSigns = [
  { id: 'aries', title: 'Aries', imgSrc: '1kos.jpg' },
  { id: 'taurus', title: 'Taurus', imgSrc: '2bika.jpg' },
  { id: 'gemini', title: 'Gemini', imgSrc: '3ikrek.jpg' },
  { id: 'cancer', title: 'Cancer', imgSrc: '4rak.jpg' },
  { id: 'leo', title: 'Leo', imgSrc: '5oroszlan.jpg' },
  { id: 'virgo', title: 'Virgo', imgSrc: '6szuz.jpg' },
  { id: 'libra', title: 'Libra', imgSrc: '7merleg.jpg' },
  { id: 'scorpio', title: 'Scorpio', imgSrc: '8skorpio.jpg' },
  { id: 'sagittarius', title: 'Sagittarius', imgSrc: '9nyilas.jpg' },
  { id: 'capricorn', title: 'Capricorn', imgSrc: '10bak.jpg' },
  { id: 'aquarius', title: 'Aquarius', imgSrc: '11vizonto.jpg' },
  { id: 'pisces', title: 'Pisces', imgSrc: '12halak.jpg' },
];

const JegyJatek = () => {
  const [jegyek, setJegyek] = useState(zodiacSigns.map((jegy) => ({ ...jegy, hely: null })));

  const handleDrop = (index, jegy) => {
    const updatedJegyek = [...jegyek];
    updatedJegyek[index].hely = jegy;
    setJegyek(updatedJegyek);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="jegy-jatek">
        <div className="jegyek">
          {jegyek.map((jegy, index) => (
            <Jegy key={jegy.id} jegy={jegy} index={index} onDrop={handleDrop} />
          ))}
        </div>
        <div className="dobozok">
          {zodiacSigns.map((jegy) => (
            <Doboz key={jegy.id} jegy={jegy} />
          ))}
        </div>
      </div>
    </DndProvider>
  );
};

const Jegy = ({ jegy, index }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { jegy, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const isActive = isDragging && jegy.hely === null;

  return (
    <div
      ref={drag}
      className={`jegy ${isActive ? 'active' : ''}`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {jegy.hely ? (
        <img src={require(`./${jegy.imgSrc}`).default} alt={jegy.title} />
      ) : (
        jegy.title
      )}
    </div>
  );
};

const Doboz = ({ jegy }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'jegy',
    // drop: (item) => onDrop(item.index, jegy),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={`doboz ${isActive ? 'active' : ''}`}>
      <img src={require(`./${jegy.imgSrc}`).default} alt={jegy.title} />
      <p>{jegy.title}</p>
    </div>
  );
};

export default JegyJatek;
