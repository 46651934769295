// CookieNotification.js

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap stílusok

const CookieNotification = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  const handleAcceptCookies = () => {
    // Jelöld meg, hogy a felhasználó elfogadta a cookie-kat
    localStorage.setItem('cookiesAccepted', 'true');
    setAcceptedCookies(true);
  };

  // Ellenőrizd, hogy a felhasználó már elfogadta-e a cookie-kat
  const cookiesAlreadyAccepted = localStorage.getItem('cookiesAccepted') === 'true';

  if (acceptedCookies || cookiesAlreadyAccepted) {
    return null; // Ne jelenjen meg a komponens, ha már elfogadták a cookie-kat
  }

  return (
    <div className="cookie-notification fixed-bottom bg-light p-3">
      <p className="mb-0">
        A weboldal cookie-kat használ a legjobb felhasználói élmény érdekében.
      </p>
      <button
        className="btn btn-success mt-2"
        onClick={handleAcceptCookies}
        disabled={acceptedCookies} // A gomb legyen letiltva, ha már elfogadták a cookie-kat
      >
        Elfogadás
      </button>
    </div>
  );
};

export default CookieNotification;
