import React, { useState } from "react";
import { DisLikeIcon, LeckekIkon, HibajelentesIkon, KommentekIcon, KukaIcon, KupaIcon, LikeIcon, ValidacioIkon, AnalogiaFeltoltesIkon, PoszolasIkon, SearchIkon } from "../../utils/Ikonok";
import ChatAnimation from "./ChatAnimation";
import {FeatueElem} from "./FeatureElem";
import "../../styles/backgroundAnimation.css"; // Importáljuk a CSS fájlt
import { usePermissions } from '../userkezeles/PermissionContext';


const FunkciokListaja = () => {
    const { hibajelentesSzSzint,
        posztLetrehozasSzSzint,
        analogiaLetrehozasSzSzint,
        analogiaErtekelesSzSzint,
        kommentelesSzSzint,
        hibajelentesSzavazatSzSzint,
        analogiaKeresoSzSzint } = usePermissions();

    const [collapseState, setCollapseState] = useState({
        validacioOpen: false,
        hozzaadasOpen: false,
        hibaJelzeseOpen: false,
        analogiaTorlesOpen: false,
        posztolasOpen: false,
        kommentOpen: false,
        szavazasOpen: false,
        leckekOpen: false,
        gyakorloOpen: false,
        analogiakeresoOpen: false,
      });

    const toggleCollapse = (collapseName) => {
        setCollapseState((prevState) => ({
          ...prevState,
          [collapseName]: !prevState[collapseName],
        }));
      };

    return (
        <div>
                <div className="m-5"/>
                <h3 className="text-center">Regisztráció után elérhető:</h3>
                <div className="row justify-content-center my-4">

                    <FeatueElem
                        icon={<LeckekIkon/>}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"leckekOpen"}
                        collapseBoolean={collapseState.leckekOpen }
                        title="Asztrológia leckék"
                        leiras="Értsd meg átlátható, rövid leckéken keresztül az asztrológia és a szimbólumok világát!"
                        info={<>
                            <i>Az elvégzett leckék után pontokat kapsz, melyekkel újabb szintek nyílnak meg számodra!</i>
                        </>
                        }
                    />

                    <FeatueElem
                        icon={<KupaIcon/>}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"gyakorloOpen"}
                        collapseBoolean={collapseState.gyakorloOpen }
                        title="Szimbólum gyakorló"
                        leiras="Teszteld a megszerzett tudásod, tanulj analógiákat játékosan!"
                        info={<>
                            <i>Az megszerzett tapasztalati pontoktól függően különböző analógia gyakorlókhoz férhetsz hozzá</i>
                        </>
                        }
                    />
                </div>

                <hr className="m-5"/>
                <h3 className="text-center">Tapasztalatot gyűjtve további funkciók válnak elérhetővé:</h3>
                <div className="row justify-content-center py-4">
                    <FeatueElem
                            icon={<KommentekIcon size={40}/>}
                            szukseges_szint={kommentelesSzSzint}
                            toggleCollapse={toggleCollapse}
                            toggleszoveg={"kommentOpen"}
                            collapseBoolean={collapseState.kommentOpen }
                            title="Kommentelés"
                            leiras="Fűzz hozzá valami érdekeset az egyes bejegyzésekhez!"
                            info={<>
                                    <i>Minden új létrehozott komment után 4 pont jár. </i>
                                    <hr/>
                                    <b>Naponta ennyi kommentet hozhatsz létre:</b>
                                    <ul>
                                       <li>2. szinttől napi 2 </li>
                                        <li>3. szinttől napi 4 </li>
                                        <li>4. szinttől napi 6 </li>
                                        <li>5. szinttől napi 8 </li>
                                        <li>6. szinttől és afelett napi 10 </li>
                                    </ul>
                            </>
                            }
                        />
                    <FeatueElem
                        icon={<><LikeIcon/><DisLikeIcon/></>}
                        szukseges_szint={hibajelentesSzavazatSzSzint}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"szavazasOpen"}
                        collapseBoolean={collapseState.szavazasOpen }
                        title="Szavazás"
                        leiras="Dönts asztrológiai kérdésekben szavazatoddal!"
                        info={<>
                            <i>Jelenleg nincs korlátozás erre vonatkozóan </i>
                        </>
                        }
                    />
                    <FeatueElem
                        icon={<PoszolasIkon/>}
                        szukseges_szint={posztLetrehozasSzSzint}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"posztolasOpen"}
                        collapseBoolean={collapseState.posztolasOpen }
                        title="Posztolás"
                        leiras="Ossz meg érdekességeket, fontos információkat vagy vicces mémeket a közösségi felületen!"
                        info={<>
                       <i>Minden új poszt megosztásáért 10 pont jár. </i>
                            <hr/>
                            <b>Naponta ennyi posztot hozhatsz létre:</b>
                            <ul>
                                <li>4. szinttől napi 1 </li>
                                <li>5. szinttől napi 2 </li>
                                <li>6. szinttől napi 3</li>
                                <li>7. szinttől és afelett napi 4 </li>
                            </ul>
                        </>
                        }
                    />
                    <FeatueElem
                        icon={<HibajelentesIkon/>}
                        szukseges_szint={hibajelentesSzSzint}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"hibaJelzeseOpen"}
                        collapseBoolean={collapseState.hibaJelzeseOpen }
                        title="Hiba jelzése"
                        leiras="Jelezd, ha szerinted valaki egy helytelen analógiát töltött fel!"
                        info={<>
                       <i>Minden új hiba jelentéséért 4 pont jár. </i>
                                    <i> Ha a jelentett hiba elfogadásra kerül (tényleg hibás), további 12 pont jár</i>
                                    <hr/>
                                    <b>Naponta ennyi analógiát lehet jelenteni hibaként</b>
                                    <ul>
                                       <li>6. szinttől napi 4 </li>
                                    <li>7. szinttől napi 6 </li>
                                    <li>8. szinttől napi 8 </li>
                                    <li>9. szinttől és afelett napi 10 </li>
                                    </ul>
                        </>
                        }
                    />
                <FeatueElem
                        icon={<ValidacioIkon/>}
                        szukseges_szint={analogiaErtekelesSzSzint}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"validacioOpen"}
                        collapseBoolean={collapseState.validacioOpen }
                        title="Analógia validáció"
                        leiras="Tapasztalt felhasználóként hagyd jóvá az egyes analógiák helyességét"
                        info={<>
                        <i>Minden analógia vlidációért 1 pont jár</i>
                                        <hr/>
                                        <b>Naponta ennyi analógiát lehet validálni</b>
                                        <ul>
                                            <li>8. szinttől napi 50 </li>
                                            <li>9. szinttől napi 100 </li>
                                            <li>10. szinttől napi 200 </li>
                                            <li>11. szinttől és afelett napi 300 </li>
                                        </ul>
                        </>
                        }
                    />
                <FeatueElem
                        icon={<SearchIkon size={40}/>}
                        szukseges_szint={analogiaKeresoSzSzint}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"analogiakeresoOpen"}
                        collapseBoolean={collapseState.analogiakeresoOpen }
                        title="Analógia kereső"
                        leiras="Keress rá analógiákra egyszerűen, vagy összetett szűrő feltételeken keresztül!"
                        info={<>
                        <i>Egyszerre maximum 100 analógia listázható le</i></>
                        }
                    />
                    <FeatueElem
                        icon={<AnalogiaFeltoltesIkon size={40} />}
                        szukseges_szint={analogiaLetrehozasSzSzint}
                        toggleCollapse={toggleCollapse}
                        toggleszoveg={"hozzaadasOpen"}
                        collapseBoolean={collapseState.hozzaadasOpen }
                        title="Analógia hozzáadás"
                        leiras="Bővítsd az adatbázist a saját analógia ötleteiddel tapasztalt felhasználóként!"
                        info={<>
                        <i>Minden analógia feltötés után 10 pont jár</i>
                                        <hr/>
                                    <b>Naponta ennyi analógiát lehet létrehozni</b>
                                    <ul>
                                    <li>10. szinttől napi 10 </li>
                                    <li>11. szinttől napi 20 </li>
                                    <li>12. szinttől  napi 40 </li>
                                    <li>13. szinttől és napi 70 </li>
                                    <li>14. szinttől és afelett napi 100 </li>
                                    </ul>
                        </>
                        }
                    />
                </div>      
        </div>
    );

}
export default FunkciokListaja;
