import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = ({ email, username }) => {
    // Adatkezelési nyilatkozat generálása
    const generatePrivacyPolicy = () => {
        return (
            <Container className="mt-5">
                <Row>
                    <Col>
                        <h1 className='text-center m-4'>Adatkezelési Nyilatkozat</h1>
                        <div className='text-center text-secondary m-4'>
                            Az alábbiakban található adatkezelési nyilatkozat az asztrovaros.hu weboldalon belül történő adatkezelésre vonatkozik.
                        </div>
                        <h3>Felhasználó adatai</h3>
                        <p>
                            A Szolgáltatás igénybevétele során Ön bizonyos személyes adatokat ad meg, mint például az e-mail címe és a felhasználóneve. Ezeket az adatokat az Ön hozzájárulása alapján kezeljük, és azokat kizárólag a Szolgáltatás nyújtásához használjuk fel.
                        </p>
                        <h3>Jelszóvédelem</h3>
                        <p>
                            Az Ön jelszava SHA256-os titkosítással van tárolva, így az Ön által megadott jelszó biztonságban van a támadókkal szemben.
                        </p>
                        <h3>Felhasználóneve</h3>
                        <p>
                            A felhasználónevet azonosításra és belépésre használjuk a Szolgáltatásba.
                            Az ön felhasználóneve nyilvános lesz más felhasználók előtt
                        </p>
                        <h3>Email</h3>
                        <p>
                            Az ön email címe nem lesz látható más felhasználók számára. <br/>
                            Viszont küldhetünk önnek emaileket elfelejtett jelszó és email cím megerősítése esetén.
                        </p>
                        <h3>Egyéb adatok</h3>
                        <p>
                            A weboldal a regisztációt követően további adatokat kérhet, amiket opcionális/nem kötelező megadni. Ezek más felhasználók számára is láthatóak lehetnek
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    };

    return (
        <div>
            {generatePrivacyPolicy()}
        </div>
    );
};

export default PrivacyPolicy;
