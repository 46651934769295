import React, { useContext, useState, useEffect } from 'react';
import AuthContext from "../../userkezeles/AuthContext";
import EditTest from './EditTest';
import ViewTest from './ViewTest';
import { Button } from 'react-bootstrap';

function Test({ test, setMessage }) {
  let { isSzerkeszto } = useContext(AuthContext);

  const transformToUserTestMode = (test) => {
    return {
      ...test,
      questions: test.questions.map(question => ({
        ...question,
        answers: question.answers.map(answer => ({
          ...answer,
          is_correct: false
        }))
      }))
    };
  };

  const [editingTestState, setEditingTestState] = useState(test);
  const [userTestState, setUserTestState] = useState(transformToUserTestMode(test));
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setEditingTestState(test);
    setUserTestState(transformToUserTestMode(test));
  }, [test]);

  const updateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...(isEditing ? editingTestState.questions : userTestState.questions)];

    if (updatedQuestion === null) {
      updatedQuestions.splice(index, 1);
    } else {
      updatedQuestions[index] = updatedQuestion;
    }

    if (isEditing) {
      setEditingTestState({ ...editingTestState, questions: updatedQuestions });
    } else {
      setUserTestState({ ...userTestState, questions: updatedQuestions });
    }
  };

  return (
    <div className='my-3 mb-5'>
      {isSzerkeszto &&
        <Button variant="info" onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? 'Stop Editing' : 'Edit'}
        </Button>
      }
      {isEditing ? (
        <EditTest {...{ updateQuestion, testState: editingTestState, setTestState: setEditingTestState, isEditing }} />
      ) : (
        <ViewTest {...{ setMessage, updateQuestion, testState: userTestState }} />
      )}
    </div>
  );
}

export default Test;
