import React, { useContext, useState } from 'react';
import AuthContext from './AuthContext';
import {check_username} from '../../utils/validateloginregister';
import { Nav } from "react-bootstrap";


const LoginPage = () => {
    const { loginUser } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameValid, setUsernameValid] = useState(true);

    const handleUsernameChange = (event) => {
        const username = event.target.value
        const formattedUsername = username.trim().toLowerCase();

        const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        if (alphanumericRegex.test(formattedUsername) && formattedUsername.length <= 15 || username == '') {
            setUsername(formattedUsername);
            setUsernameValid(check_username(formattedUsername));
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLoginSubmit = (event) => {
        if (usernameValid) {
            loginUser(event);
        } else {
            alert("Helytelen felhasználónév!");
        }
    };

    return (
        <div>
            <h1 className="text-center m-5">Bejelentkezés</h1>
            <form onSubmit={handleLoginSubmit} className="input-group m-3 row">
                <input
                    className={`form-control ${!usernameValid ? 'is-invalid' : ''}`}
                    type="text"
                    name="username"
                    placeholder="Felhasználónév"
                    value={username}
                    onChange={handleUsernameChange}
                />
                {!usernameValid && (
                    <div className="invalid-feedback">
                        A felhasználónév csak ékezet nélküli kisbetűket és számokat tartalmazhat! (Maximum 15 karakterben)
                    </div>
                )}
                <input
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder="Jelszó"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <input className="btn btn-outline-primary" type="submit" value="Belépés" />
            </form>

            <div className='pt-5 text-center text-secondary'>
                <p>Még nincs fiókod? Regisztrálj itt:</p>
                <Nav.Link href="/register" className="text-center text-primary">
                    Regisztráció
                </Nav.Link>
            </div>
            {/* <div className='pt-3 text-center text-secondary'>
                <p>Elfelejtetted a jelszavad?</p>
                <Nav.Link href="/elfelejtett-jelszo" className="text-center text-primary">
                    Új jelszó igénylése
                </Nav.Link>
            </div> */}
        </div>
    );
}

export default LoginPage;
