import React from 'react';
import EgyszeruAnalogiaLink from './EgyszeruAnalogiaLink';

const EgyszeruAanalogiaLinkCsoportHeader = ({ aanalogiaList, props }) => {
  return (
    <div className="m-3 row justify-content-center">
      {aanalogiaList.map((analogia, index) =>
        props.osszetett ? (
          <div key={index}></div>
        ) : (
          <div className="col-2 text-center text-truncate" key={index}>
            <EgyszeruAnalogiaLink
              tarolo={props.aanalogia_endpoint}
              aanalogia={analogia}
            />
          </div>
        )
      )}
      <hr />
    </div>
  );
};

export default EgyszeruAanalogiaLinkCsoportHeader;
