import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../userkezeles/AuthContext";
import { StartIcon } from '../../utils/Ikonok';
import {useFetching, usePost} from "../auth/apicalls"
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"
import {showErrorAlert} from "../alert/ErrorAlert"

function GameOptions() {
  const navigate = useNavigate();
  let { user } = useContext(AuthContext);
  const { data:games, isError, isLoading } = useFetching('/api/gyakorloopcio/');

  const { mutate: postGyakorloInditas} = usePost(`/api/gyakorlo/`, {
    onSuccess: (data) => {
      navigate(`/gyakorlo/${data.data.id}`);
    },
    onError: (error) => {
        showErrorAlert({
            title: "Nem sikerült elindítani a gyakorlót és menteni az eredményt!",
            text: error.response.data.user_error
          });
      },
  });

  const getPlayButtonColor = (game) => {
    if (game.max_pont < 5) {
      return "success"; // green color
    } else if (game.max_pont < 15) {
      return "warning"; // yellow color
    } else {
      return "danger"; // red color
    }
  };

  const handlePlayClick = async (game) => {
    postGyakorloInditas({
      nev: game.nev,
      max_pont: game.max_pont,
      kerdesek_szama: game.kerdesek_szama,
      valaszlehetosegek_szama: game.valaszlehetosegek_szama,
      gyakorlotipus: game.gyakorlotipus,
      analogiatipus_szuro: game.analogiatipus_szuro,
      csoport_szuro:game.csoport_szuro
    })
  };

  return (
    <>
    {isLoading && <Loading size={60} />}
    {isError && <ErrorAlert show={isError} text="" />}
    {games && (
    <div className="mt-4">
      <h2 className="mt-3 mb-5 text-center">Analógia Gyakorló központ</h2>
      {Object.keys(games).map((szuksegesSzint) => (
        <div key={szuksegesSzint}>
          <h3>Szükséges Szint: {szuksegesSzint}</h3>
          <table className="table">
            <thead >
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Név</th>
                <th className="text-center">Kérdés / opciók</th>
                <th className="text-center">Max pont</th>
              </tr>
            </thead>
            <tbody>
              {games[szuksegesSzint].map((game) => (
                <tr key={game.id}>
                  <td className="text-center">
                    <button
                      className={`btn btn-${getPlayButtonColor(game)} btn-sm`}
                      onClick={() => handlePlayClick(game)}
                      disabled={user.felhasznaloi_szint < szuksegesSzint}
                    >
                      <StartIcon/>
                    </button>
                  </td>
                  <td className="text-center">{game.nev}</td>
                  <td className="text-center">{game.kerdesek_szama}/{game.valaszlehetosegek_szama}</td>
                  <td className="text-center">{game.max_pont}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr></hr>
        </div>
      ))}
    </div>)}
    </>
  );
}

export default GameOptions;
